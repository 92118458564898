import {
  BlurCircular as BlurCircularIcon,
  CalendarToday as CalendarTodayIcon,
  DeveloperBoard as DeveloperBoardIcon,
  DeviceHub as DeviceHubIcon,
  Event as EventIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  GraphicEq as GraphicEqIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  ViewModule as ViewModuleIcon,
} from '@material-ui/icons';

import navTemplateItems from './templates';
import navUsersItems from './users';

const items = [
  {
    icon: SupervisedUserCircleIcon,
    items: navUsersItems,
    module: navUsersItems.map((item) => item.module),
    title: 'access',
  },
  {
    href: '/settings/assistant',
    icon: BlurCircularIcon,
    module: 'assistant',
    title: 'assistant',
  },
  {
    href: '/settings/calendar',
    icon: CalendarTodayIcon,
    module: 'calendar',
    title: 'calendar',
  },
  {
    href: '/settings/campaigns',
    icon: GraphicEqIcon,
    module: 'campaigns',
    title: 'campaigns',
  },
  {
    href: '/settings/events',
    icon: EventIcon,
    module: 'events',
    title: 'events',
  },
  {
    href: '/settings/flows',
    icon: DeviceHubIcon,
    module: 'flows',
    title: 'flows',
  },
  {
    href: '/settings/marketplace',
    icon: ViewModuleIcon,
    module: 'marketplace',
    title: 'Marketplace',
  },
  {
    href: '/settings/parameters',
    icon: DeveloperBoardIcon,
    module: 'parameters',
    title: 'parameters',
  },
  {
    icon: FileCopyOutlinedIcon,
    items: navTemplateItems,
    module: navTemplateItems.map((item) => item.module),
    title: 'templates',
  },
];

export default items;
