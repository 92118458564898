import * as Yup from 'yup';

import MESSAGE from '../../constants/messages';

export const eventFormSchema = Yup
  .object()
  .shape({
    match: Yup
      .number()
      .required(MESSAGE.REQUIRED),
    actions: Yup
      .array()
      .of(Yup.string()) 
      .required(MESSAGE.REQUIRED),
    activations: Yup
      .array()
      .of(Yup.number()) 
      .required(MESSAGE.REQUIRED),
  });
