import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card_round: {
    borderRadius: theme.spacing(2),
  },
  document_extension: {
    backgroundColor: theme.palette.primary.main,
    ...theme.typography.body1,
    color: '#ffffff',
    fontWeight: 'bold',
    borderRadius: '6px',
    padding: '2px 6px',
    marginLeft: '6px',
  },
}));

export default useStyles;
