export const SERVICES = {
  ASSETS: '/services/assets',
  ASSETS_GROUPS: '/services/assets/groups',

  ACCOUNTS: '/private/accounts',
  ACCOUNTS_CALCULATE_PROJECTED_DATA: '/private/accounts/calculate_projected_data',
  ACCOUNTS_CARDS: '/private/accounts/cards',
  ACCOUNTS_CHILDS: '/private/accounts/daughters',
  ACCOUNTS_DESTINATION: '/private/accounts/destination',
  ACCOUNTS_DOCUMENTS: '/private/accounts/documents',
  ACCOUNTS_FILTER: '/private/public/clients_accounts/filter',
  ACCOUNTS_FILTER_PROPERTIES: '/private/accounts/properties_filter',
  ACCOUNTS_MAIN: '/private/accounts/father',
  ACCOUNTS_MASSIVE_MOVEMENTS: '/private/accounts/massivemovements',
  ACCOUNTS_MASSIVE_PRODUCTS: '/private/accounts/massive/products',
  ACCOUNTS_MOVEMENTS: '/private/accounts/movements',
  ACCOUNTS_OPERATIONS: '/private/accounts/operations',
  ACCOUNTS_PAYMENT_CONFIG: '/private/payments_config',
  ACCOUNTS_PROCESSOR: '/private/accounts/processor',
  ACCOUNTS_REFERENCE: '/private/accounts/reference',
  ACCOUNTS_RELATIONSHIPS: '/private/account_relationship',
  ACCOUNTS_SIMULATOR: '/private/accounts/v2/table_virtual',
  ACCOUNTS_TRANSACTIONS: '/accounts/:account/transactions',
  ACCOUNTS_TABLE: '/private/accounts/table',
  ACCOUNTS_TABLE_IDEAL: '/private/accounts/ideal_table',
  ACCOUNTS_GET_PAYMENTS_OTP: '/private/accounts/get_payments_op_template',

  APPS_CATALOG: '/private/apps/catalog',
  APPS_KEYS: '/private/apps/keys',
  APPS_KEYS_PRIVATE: '/private/thirds/apps_key',
  APPS_MAPPING: '/private/apps/mapping',
  APPS_QUERY: '/private/apps/query',
  APPS_TOKEN_GET: '/private/apps/gettokens',
  APPS_TOKEN_SET: '/private/apps/token',
  APPS_DEPOSITS: '/private/apps/orders',

  ASSISTANT_THREAD: '/internal/assistants/thread',
  ASSISTANT_DELETE_THREAD: '/internal/assistants/thread/:threadId',
  ASSISTANT_CREATE_MESSAGE: '/internal/assistants/message',
  ASSISTANT_READ_MESSAGE: '/internal/assistants/message/:threadId',
  ASSISTANT_RUNS: '/internal/assistants/runs/:threadId',

  BANKS: '/private/banks',
  BANKS_ACCOUNTS: '/private/banks/accounts',
  BANKS_ACCOUNTS_TYPE: '/private/banks/accounts_type',
  BANKS_CALENDAR: '/private/banks/calendar',
  BANKS_PARTNER: '/private/banks/partner',
  BANKS_LEVELS: '/private/banks/levels',
  BANKS_TAX: '/private/banks/tax',
  BANKS_OPERATIONS: '/private/banks/operations',

  BLOCKFON_IMEIS: '/private/blockfon/imei',

  BLACK_LIST_MASSIVE: '/private/blacklist/massive',

  CAMPAIGNS: '/private/campaign',
  CAMPAIGNS_COMPARATOR: '/private/campaign/comparators',
  CAMPAIGNS_CONDITIONALS: '/private/campaign/conditionals',
  CAMPAIGNS_CONDITIONALS_FIELDS: '/private/campaign/conditionals/fields',
  PRUEBA1: '/private/public/product_field_catalog',
  CAMPAIGNS_HISTORICAL: '/private/campaign/historical',
  CAMPAIGNS_LINKS: '/private/campaign/links',
  CAMPAIGNS_RESULTS: '/private/campaign/result',
  CAMPAIGNS_TYPES: '/private/campaign/types',
  CATALOG_BASE_FIELD: '/private/public/product_field_catalog',
  CUSTOMER_ACTIONS: '/private/thirds/customer_actions',
  SEND_CUSTOMER_ACTIONS: '/marketplace/apps/tools/send_customer_actions',
  DEFINED_PRODUCTS: '/private/config/products',
  OPERATIONS_E: '/private/v2/products/operations',
  CMD_CATALOGE: '/private/accounts/cmd',
  ACTIONS: '/private/accounts/actions',

  CARDS: '/cards/cards',
  CARDS_PAY: '/marketplace/apps/tools/cardpay',

  CATALOGS_FIELDS: '/private/catalog/templates/fields',
  CATALOGS_TEMPLATES: '/private/catalog/public_template/id',
  CATALOGS_ZIPCODE: '/private/codecatalog/code',
  CATALOGS_ROLES: '/private/catalog/roles',

  CC: '/private/cc',

  COMPANY: '/private/company',
  COMPANY_CONSTANTS: '/private/company/constants',
  COMPANY_CONSTANT_TYPES: '/private/company/constant_types',
  COMPANY_FIELDS: '/private/company/fields',
  COMPANY_MODULES: '/private/company/modules',
  COMPANY_MODULES_GROUPS: '/private/company/modules_groups',
  COMPANY_PAYMENT_METHODS: '/private/company/payment_methods',
  COMPANY_STAGES: '/private/company/stages',
  COMPANY_TRANSLATE: '/services/company/translate',

  CONNECTOR_CORE: '/internal/connector/:company/core',
  CONNECTOR_SET_CLABE: '/internal/connector/:company/setclabe',
  CONNECTOR_FLOW: '/internal/connector/:company/flows/:flow',

  CONFIG_SERVICES: '/private/service_config',
  CONFIG_VIEWS: '/private/views/config',
  CONFIG_VIEWS_CATALOGS: '/private/views/config-catalog',
  CONFIG_VIEWS_DATA: '/private/dynamic/views',

  CRONTAB: '/private/crontab',

  DASHBOARD_CHARTS: '/private/dashboard',
  DASHBOARD_CHARTS_DATA: '/private/dashboard/data',
  DASHBOARD_CHARTS_TYPE: '/private/dashboard/charts',

  EVENTS: '/private/company/events_config',
  EVENTS_ACTIONS: '/private/accounts/cmd&alias=event',
  EVENTS_CATALOG: '/private/company/catalog_events',
  EVENTS_FIELDS: '/private/company/events_fields',
  EVENTS_VALUES: '/private/company/events_values',

  FINANCIAL_CAT: '/internal/financial/cat_pc',
  FINANCIAL_IRR: '/internal/financial/irr',

  FLOWS: '/private/diagrams/flows',
  FLOWS_CLASS: '/private/diagrams/class',
  FLOWS_NODES: '/private/diagrams/nodes',
  NODES_CATALOG: '/private/administration/catalog_nodes',
  FLOWS_TYPES: '/private/diagrams/flows_types',

  GROUPS: '/private/groups',

  INVOICES: 'private/thirds/invoices',
  INVOICE_ACTIONS: '/marketplace/apps/facturama/bill/invoices',
  INVOICE_DETAILS: '/marketplace/apps/facturama/bill/:id',
  INVOICE_EMAIL: '/marketplace/apps/facturama/bill/send_email',
  INVOICE_DOWNLOAD: '/marketplace/apps/facturama/bill/download',

  KITS: '/private/thirds/kits',
  KITS_DOCUMENTS: '/private/thirds/kit_doc',

  MARKETPLACE_CARDS_TOKEN: '/marketplace/apps/demo/cards/token',
  MARKETPLACE_CC_PDLFP: '/private/marketplace/apps/cc/pdlfp',
  MARKETPLACE_CONEKTA_EVENTS: '/marketplace/apps/conekta/events',
  MARKETPLACE_CONEKTA_EVENTS_RESEND: '/marketplace/apps/conekta/resend_event',
  MARKETPLACE_CONEKTA_ORDER_CREATE: '/marketplace/apps/conekta/order/v2',
  MARKETPLACE_CONEKTA_ORDER_GET: '/marketplace/apps/conekta/order/get',
  MARKETPLACE_CONEKTA_ORDER_READ: '/marketplace/apps/conekta/order/:account',
  MARKETPLACE_CREDITS_PAYMENTS: '/marketplace/apps/credits/payments',
  MARKETPLACE_CREDITS_PEOPLE_SCORE: '/marketplace/apps/credits/people_credit_score',
  MARKETPLACE_COMMERCE: '/marketplace/apps/dynamicardpay/v2/company/commerce',
  MARKETPLACE_CREDENCIAL_BALANCE: '/marketplace/apps/demo/credencial/authorization/balance',
  MARKETPLACE_CREDENCIAL_CARD_CHANGE_STATUS: '/marketplace/apps/demo/credencial/service_card/change_status',
  MARKETPLACE_CREDENCIAL_CARD_CONSULT: '/marketplace/apps/demo/credencial/service_card/consult',
  MARKETPLACE_DATACULTR_DEVICE_ACTION: '/marketplace/apps/datacultr/device/:action',
  MARKETPLACE_DATACULTR_DEVICE_HISTORY: '/marketplace/apps/datacultr/device/history',
  MARKETPLACE_DATACULTR_DEVICE_REGISTRATION: '/marketplace/apps/datacultr/device/registration_status',
  MARKETPLACE_DATACULTR_DEVICE_STATUS: '/marketplace/apps/datacultr/device/status',
  MARKETPLACE_DATACULTR_EXECUTE_NUDGE: '/marketplace/apps/datacultr/execute/nudge',
  MARKETPLACE_GROUPS_HASH: '/marketplace/apps/demo/groups/hash',
  MARKETPLACE_MATRIZ_OPENSEARCH: 'marketplace/apps/matriz/opensearch/',
  MARKETPLACE_OPENSEARCH_DELETE: 'marketplace/apps/opensearch/delete_list',
  MARKETPLACE_PAGAQUI_TRANSACT_METHOD: '/marketplace/apps/pagaqui/transact/:method',
  MARKETPLACE_PAGAQUI_PAYMENT_PROCESS: '/marketplace/apps/pagaqui/payment/process',
  MARKETPLACE_PAYMENT_METHODS: '/marketplace/apps/dynamicardpay/v2/payment_methods/list',
  MARKETPLACE_PLD_CLIENT: '/marketplace/apps/pld/client',
  MARKETPLACE_PLD_LISTS: '/marketplace/apps/pld/lists',
  MARKETPLACE_PLD_MATRIX: '/marketplace/apps/matriz/',
  MARKETPLACE_PLD_MATRIX_CLIENT: '/marketplace/apps/matriz/client',
  MARKETPLACE_PLD_REPORTS: '/marketplace/apps/matriz/reports',
  MARKETPLACE_REDECO_COMPLAINTS: '/marketplace/apps/redeco/complaints',
  MARKETPLACE_REUNE: '/marketplace/apps/reune/:type',
  MARKETPLACE_REDECO_CATALOGS: '/marketplace/apps/redeco/catalog/:name',
  MARKETPLACE_SAFEUEM_DEVICE_MOVE: '/marketplace/apps/safeuem/device/android/moveDevice',
  MARKETPLACE_STP_CLABES_QUERY: '/marketplace/apps/stp/clabes/consultClabe',
  MARKETPLACE_STP_INQUIRY_BALANCE: '/marketplace/apps/stp/consultas/saldo',
  MARKETPLACE_STP_INQUIRY_CONCILIATION: '/marketplace/apps/stp/consultas/conciliacion',
  MARKETPLACE_STP_QUERY_ORDER: '/marketplace/apps/stp/consultas/orden',
  MARKETPLACE_TOOLS_MASSIVE_CARDS: '/marketplace/apps/demo/tools/massive/cards',
  MARKETPLACE_TRANSACTIONS: '/marketplace/apps/dynamicardpay/v2/transactions',
  MARKETPLACE_TRANSACTIONS_LINKS: '/marketplace/apps/dynamicardpay/v2/transactions/link',
  MARKETPLACE_USER_KEYS: '/marketplace/apps/dynamicardpay/v2/users/keys',
  MARKETPLACE_WEETRUST_VERIFY_FLOW: '/marketplace/apps/weetrust/identity/verify_flow',

  MODULES: '/private/modules',

  NOTIFICATIONS_ALERTS: '/private/notifications/alerts',
  NOTIFICATIONS_FIELDS: '/private/notifications/get_fields',
  NOTIFICATIONS_FORMS: '/private/notifications/forms',
  NOTIFICATIONS_SURVEY: '/private/notifications/survey',
  NOTIFICATIONS_SURVEY_RELATION: '/private/notifications/survey_relation',
  NOTIFICATIONS_TEMPLATES: '/private/notifications/templates',
  NOTIFICATIONS_FORMS_DEFINED_TEMPLATES_LIST: '/private/notifications/catalogo_forms',
  NOTIFICATIONS_FORMS_DEFINED_TEMPLATES_ADD: 'private/notifications/forms',

  DOCUMENTS_TEMPLATES: '/private/notifications/docx',
  DOCUMENTS_TEMPLATES_SIGNERS: '/private/thirds/signers',
  DOCUMENTS_TEMPLATES_CLIENTS: '/private/notifications/get_elements_fields',

  ORDERS: '/private/apps/orders',
  ORDERS_CLIENT_FILTER: '/private/orders/client_filter',

  ORDERS_MANAGERS: '/private/thirds/managers',
  ORDERS_MANAGERS2: '/private/thirds/managers?limit=1',
  ORDERS_FILTER: '/private/thirds/custom_filters&params={"managers": []}',
  ORDERS2: '/private/thirds/filtered_orders&params={"managers": [], "filter":""}',
  ORDERS_LIST: '/services/tools/orders',
  ORDER_DETAIL: '/services/tools/order_detail',
  ORDER_DETAIL_TYPES: 'services/tools/order_taxo&type=event_type',

  PEOPLE: '/private/clients',
  PEOPLE_BALANCE: '/private/clients_balance',
  PEOPLE_BY_IDS: '/private/clientes_ids',
  PEOPLE_MANAGERS_REASIGN: '/private/clients/manager_reassignment',
  PEOPLE_MASSIVE: '/private/clients/massive',
  PEOPLE_MOVEMENTS: '/private/movements_by_client',
  PEOPLE_ORGANIZATIONAL: '/private/clients/organizational/v2',
  PEOPLE_ORGANIZATIONAL_OLD: 'private/clients/organizational',
  PEOPLE_PII: '/private/clients/pii',
  PEOPLE_PII_V2: '/private/v2/clients/pii',
  PEOPLE_PII_FILTER: '/private/clients/pii_filter',
  PEOPLE_RELATIONSHIP: '/private/clients/relationship',
  PEOPLE_RELATIONSHIP_ASSETS: '/private/clients/relation_assets',
  PEOPLE_TEMPLATES: '/private/clients/template',
  PEOPLE_TYPES: '/private/clients/types',
  PEOPLE_TYPES_IFRAME: '/private/clients/types/create_iframe',
  PEOPLE_DEFINED_TEMPLATES_LIST: '/private/clients/client_catalog',
  PEOPLE_DEFINED_TEMPLATES_ADD: '/private/clients/default_template',

  PLD_ACCIONS: '/services/pld/actions',
  PLD_ALERT_TYPES: '/services/pld/types_alerts',
  PLD_BACKLIST: '/services/pld/backlist',
  PLD_CATALOGS_EXTERNAL: '/services/pld/catalogo_external',
  PLD_CATALOGS_RISK: '/services/pld/risk',
  PLD_COMPLAINTS: '/services/pld/complaints',
  PLD_CONSULTATION: '/services/pld/consultation',
  PLD_INTERNAL_REPORTS: '/services/pld/internalreports',
  PLD_JURISDICTIONS: '/services/pld/jurisdictions',
  PLD_PARAMETERS: '/services/pld/parameters',
  PLD_REPORT_TYPES: '/services/pld/reportstypes',
  PLD_PREVENCION_LIST: '/marketplace/apps/prevencion_de_lavado/listas',
  TEMPLATES_CATALOG: '/private/public/datatables',

  PRODUCTS: '/private/products',
  PRODUCTS_OPERATIONS: '/private/products/operations',
  PRODUCTS_OPERATIONS_CATALOG: '/private/products/operations/catalog',
  PRODUCTS_PAYMENTS: '/private/products/operations/payments',
  PRODUCTS_TYPES: '/private/products/types',
  PRODUCTS_PROPERTIES: '/private/v2/products',
  PRODUCTS_V3: '/private/v3/products',
  CONFIG_PRODUCTS: '/private/config/products',
  PRODUCTS_SETUP: '/private/products/setup',
  PRODUCT_PROPERTIES: '/private/company/catalog_config_product',
  PRODUCTS_HEADERS: 'private/public/catalog_headers',
  FILTER_SQL: '/private/accounts/cmd',
  SUBACCOUNTS: '/private/accounts/sub_accounts',
  SUBACCOUNTS_V2: '/private/accounts/meta_sub_accounts',

  PAYMENTS: '/private/payments',
  PAYMENTS_HISTORY: '/private/payments_history',
  PAYMENTS_OPERATIONS: '/private/payments/operations',
  PAYMENTS_STATUS: '/private/payments/status',

  PASS_CODE: '/marketplace/apps/datacultr/device/get_passcode',

  REPORTS: 'private/reports/config',
  REPORTS_DATA: '/private/reports/loan_tape',
  REPORTS_FIELDS: '/private/reports/loan_fields',
  REPORTS_GENERATE: '/private/generated_reports',
  REPORTS_GENERATE_HIDE: '/private/generated_report/hide',
  REPORTS_KPI: '/private/reports/kpi',
  REPORTS_PDF: '/internal/financial/reports/panelpdf',
  REPORTS_STATICS: '/private/static_reports',

  SAT: '/private/sat/data',
  SAT_BALANCE: '/private/sat/balance',
  SAT_CFDI: '/internal/load_cfdi',
  SAT_GET_KEY: '/private/sat/keys',
  SAT_KEY: '/internal/sat/sat_key',
  SAT_MONTHLY_AMOUNTS: '/private/sat/monthly_amounts',
  SAT_PARTNERS_PERCENT: 'private/sat/partners_percent',
  SAT_STATUS: '/private/sat/query_status',
  SAT_SYNC: '/internal/sat/sat_cfdi',
  SAT_UPDATE_RECCURENT: '/private/sat/v2/keys',
  SAT_PRIVATE_STATEMENTS: '/private/sat/statements',
  SAT_INTERNAL_STATEMENTS: '/internal/sat/statements',

  SERVICES_PLD_ALERTS: '/services/pld/alerts',
  SERVICES_PLD_CATEGORY: '/services/pld/category',
  SERVICES_PLD_CRITERIA: '/services/pld/criteria',
  SERVICES_PLD_HISTORY: '/services/pld/history',
  SERVICES_PLD_MATRIX: '/services/pld/matrix',
  SERVICES_PLD_MONETARY: '/services/pld/monetary',
  SERVICES_PLD_OPERATIONS: '/services/pld/operations',
  SERVICES_PLD_OPERATIONS_TYPES: '/services/pld/operationstypes',
  SERVICES_PLD_OPP: '/services/pld/opp',
  SERVICES_PLD_TRANSACTIONAL_PROFILE: '/services/pld/transactional_profile',

  STAGES: '/private/conditionals/stage_configuration',

  TAXONOMY: '/services/taxonomy',

  REUNE: 'marketplace/apps/reune/send_data',

  THIRDS_ANALYSIS: '/private/thirds/analysis',
  THIRDS_MANAGERS: '/private/thirds/managers',
  THIRDS_NOTES: '/private/thirds/notes',

  USERS: '/private/users',
  USERS_ADD: '/users/add',
  USERS_BINNACLE: '/private/users/binnacle',
  USERS_CLIENTS: '/users/clients',
  USERS_CREATE: '/users/create',
  USERS_DETAILS: '/private/users_details',
  USERS_ENABLE_DISABLE: '/users/adminEnableDisableduser',
  USERS_GET_INFO: '/private/users/get_info',
  USERS_GET_USER: '/users/getUser',
  USERS_KEYS: '/private/users/keys',
  USERS_OWNER: '/users/owner',
  USERS_ROLES: '/private/users/roles',
  USERS_ROLES_UPDATE: '/private/users/users_roles',
  USERS_ROLES_TEMPLATE: '/private/users/rolestemplate',
  USERS_UPDATE_ATTRIBUTES: '/users/updateUserAttributes',
  USERS_WEBCLIENTS: '/users/webclients',
  USERS_SIGNIN_DOCUMENT: '/users/signin_documents',
  USERS_PRESIGNIN_DOCUMENT: '/users/presignin_documents',
  USERS_CHECK_DOCUMENT: '/users/checkKeyExist',

  WS_PUBLISH: '/internal/wspublish',

  ZIPCODES: '/services/zipcodes',
};

export const CONF = {
  [SERVICES.PEOPLE_TEMPLATES]: {
    getById: false,
    sort: 'score',
  },
  [SERVICES.DASHBOARD_CHARTS_DATA]: {
    getById: false,
  },
};
