import styles from './styles.module.css';

function LoaderComponent() {

  return (<>
    <div className={styles.loader} />
  </>);
}

export default LoaderComponent;
