import {
  Fragment,
  Suspense,
  lazy,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import LoadingScreen from 'components/Loaders/LoadingScreen';
import PermissionGuard from 'components/PermissionGuard';

import authenticationRoutes from './authentication';
import dashboardRoutes from './dashboard';
import demoRoutes from './demo';
import internalRoutes from './internal';
import publicRoutes from './public';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const redirect = () => <Redirect to="/dashboard" />;

const landingRoutes = {
  path: '*',
  routes: [error404],
};

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, index) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Permission = route.permission
            ? PermissionGuard
            : Fragment;

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes
                      ? (renderRoutes(route.routes))
                      :
                      routes.permission ?
                        <Permission permission={route.permission} children={<Component {...props} />} /> :
                        <Component {...props} />
                    }
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense >
  ) : null;

function Routes() {
  return renderRoutes([
    {
      exact: true,
      path: '/',
      component: redirect,
    },
    demoRoutes,
    authenticationRoutes,
    internalRoutes,
    publicRoutes,
    dashboardRoutes,
    landingRoutes,
    error404,
  ]);
}

export default Routes;
