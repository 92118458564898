import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

function LoaderCardContainer({ children }) {
  return (<Box mt={2}>
    <Card>
      <CardContent
        children={children}
        style={{
          padding: 15,
        }}
      />
    </Card>
  </Box>);
}

LoaderCardContainer.propTypes = {
  children: PropTypes.node,
};

function Loader({ align, color, message, size, variant }) {
  const Component = variant === 'card'
    ? LoaderCardContainer
    : Fragment;

  return (<Component>
    <Grid container justifyContent={align}>
      <CircularProgress
        color={color}
        size={size}
      />
      {message && <Typography
        children={message}
        style={{
          marginLeft: 10,
        }}
        variant="body1"
      />}
    </Grid>
  </Component>);
}

Loader.defaultProps = {
  align: 'center',
  message: 'Cargando...',
  color: 'secondary',
  size: 30,
};

Loader.propTypes = {
  align: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  message: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.oneOf(['card']),
};

export default Loader;
