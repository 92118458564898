import {
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  SELECT_PROP_TYPES,
  SELECT_AUTOCOMPLETE_PROP_TYPES,
  SELECT_MENU_PROP_TYPES,
  SELECT_MULTIPLE_PROP_TYPES,
  GROUP_ITEMS,
  GROUP_HEADER,
} from './constants';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { 
  useEffect, 
  useRef, 
  useState,
} from 'react';

const GroupHeader = ({ 
  children, 
  onClick, 
  isExpanded,
  tree = false,
}) => (
  <ListItem button onClick={onClick}>
    <ListItemText
      primary={
        <Typography variant="subtitle1" style={{ 
          fontWeight: 'bold',
        }}>
          {children}
        </Typography>
      }
    />
    {tree ? 
      isExpanded 
        ? <ExpandLess /> 
        : <ExpandMore />
      : null
    }
  </ListItem>
);

const GroupItems = ({ children }) => (
  <List component="div" disablePadding style={{
    paddingLeft: '15px',
  }}>
    {children}
  </List>
);

function SelectAutocomplete(props) {
  const {
    disabled,
    error,
    events = {},
    label,
    name,
    helperText,
    size,
    value,
    onBlur,
    onChange,
    tree,
  } = props;
  let { options = [] } = props;

  const [expandedGroups, setExpandedGroups] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const selectedOptionRef = useRef(null);

  const handleToggleGroup = (group) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  if (typeof options === 'function') {
    options = options();
  }

  const selectedOption = options.find((option) => String(option.id) === String(value));

  useEffect(() => {
    if (tree) {
      if (searchTerm) {
        const matchingGroups = new Set();
  
        options.forEach((option) => {
          if (option.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            matchingGroups.add(option.groupBy);
          }
        });
  
        const newExpandedGroups = {};
        matchingGroups.forEach((group) => {
          newExpandedGroups[group] = true;
        });
  
        setExpandedGroups(newExpandedGroups);
      } else {
        setExpandedGroups({});
      }
    }
  }, [searchTerm, options, tree]);

  const scrollToSelectedOption = () => {
    if (selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      onChange={(event, value) => {
        const target = {
          name,
          value: value?.id,
        };

        onChange({
          target,
        });

        if (events.onChange) {
          events.onChange(target);
        }
      }}
      onBlur={onBlur}
      onInputChange={(event, value) => {
        setSearchTerm(value);
      }}
      onOpen={() => {
        setTimeout(scrollToSelectedOption, 100);
      }}
      options={options}
      getOptionLabel={({ name }) => name}
      groupBy={({ groupBy }) => groupBy}
      value={selectedOption || null}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          fullWidth
          size={size}
          error={error}
          helperText={helperText}
          label={label}
          variant="outlined"
        />
      )}
      renderOption={(option) => {
        const isSelected = String(option.id) === String(value);
        return (
          <li
            key={option.id}
            ref={isSelected ? selectedOptionRef : null}
          >
            {option.name}
          </li>
        );
      }}
      renderGroup={(params) => {
        if (!tree) {
          return (
            <li key={params.key}>
              <GroupHeader style={{ 
                pointerEvents: 'none',
              }}>
                {params.group}
              </GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          );
        }

        const { group } = params;
        const isExpanded = expandedGroups[group] || false;

        return (
          <li key={params.key}>
            <GroupHeader
              tree={tree}
              onClick={() => handleToggleGroup(group)}
              isExpanded={isExpanded}
            >
              {group}
            </GroupHeader>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <GroupItems>{params.children}</GroupItems>
            </Collapse>
          </li>
        );
      }}
    />
  );
}

function SelectMenu(props) {
  const {
    disabled,
    emptyElement,
    loadingElements,
    error,
    events = {},
    group,
    tree,
    helperText,
    label,
    name,
    onBlur,
    onChange,
    size,
    value,
  } = props;
  let { options = [] } = props;

  if (typeof options === 'function') {
    options = options();
  }

  const groups = group || tree ? [...new Set(
    options.map(({ groupBy }) => groupBy),
  )] : [];

  return (
    <TextField
      autoComplete={true}
      disabled={disabled || loadingElements}
      error={error}
      fullWidth={true}
      helperText={helperText}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      name={name}
      select={true}
      SelectProps={{
        native: true,
      }}
      size={size}
      variant="outlined"
      value={value}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          events.onChange(e?.target);
        }
      }}
    >
      {loadingElements ? (
        <option>
          Cargando...
        </option>
      ) : (
        <>
          {emptyElement && <option>{emptyElement}</option>}

          {group
            ? groups.map((item, index) => {
              const opt = options.filter((_) => _.groupBy === item);

              return opt.length > 0 ? (
                <optgroup key={index} label={item} style={{ 
                  fontStyle: 'normal',
                }}>
                  {opt.map(({ id, name }, key) => (
                    <option value={id} key={key}>
                      {name}
                    </option>
                  ))}
                </optgroup>
              ) : null;
            })
            : (Array.isArray(options) ? options : []).map(({ id, name }, key) => (
              <option value={id} key={key}>
                {name}
              </option>
            ))}
        </>
      )}
    </TextField>
  );
}

function SelectMultiple(props) {
  const {
    disabled,
    loadingElements,
    error,
    events = {},
    helperText,
    label,
    name,
    onChange,
    size,
  } = props;
  let { options = [], value = [] } = props;

  if (!Array.isArray(value)) {
    value = [];
  }

  if (typeof options === 'function') {
    options = options();
  }

  return (<FormControl
    fullWidth
    size={size}
    variant="outlined"
  >
    <InputLabel children={loadingElements ? 'Cargando...' : label} />
    <Select
      disabled={disabled || loadingElements}
      error={error}
      id="demo-mutiple-checkbox"
      input={<OutlinedInput label={label} />}
      multiple
      name={name}
      onChange={(e) => {
        onChange(e);

        if (events.onChange) {
          events.onChange(e?.target);
        }
      }}
      renderValue={(selected) => `${selected.length} seleccionado${selected.length !== 1 ? 's' : ''}`}
      value={value}
      variant="outlined"
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.id}>
          {!disabled && (<Checkbox
            checked={value.includes(option.id)}
          />)}
          <ListItemText 
            primary={option.name}
            secondary={option.description ? (
              <span style={{ 
                color: 'rgba(0, 0, 0, 0.6)', 
                fontSize: '0.875rem',
              }}>
                {option.description}
              </span>
            ) : null
            }
          />
        </MenuItem>
      ))}
    </Select>
    <FormHelperText children={helperText} error />
  </FormControl>);
}

function SelectControl(props) {
  const {
    disabled,
    label,
    name,
    settings: {
      autocomplete = false,
      emptyElement,
      loadingElements,
      group = false,
      tree = false,
      multiple = false,
      options = [],
    },
    events = {},
    size,
    value,
    error,
    helperText,
    onBlur,
    onChange,
  } = props;
  const Control = multiple
    ? SelectMultiple
    : (autocomplete ? SelectAutocomplete : SelectMenu);

  return (
    <Control
      disabled={disabled}
      emptyElement={emptyElement}
      loadingElements={loadingElements}
      error={error}
      events={events}
      group={group}
      tree={tree}
      helperText={helperText}
      label={label}
      multiple={multiple}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      size={size}
      value={value}
    />
  );
}

GroupHeader.propTypes = GROUP_HEADER;
GroupItems.propTypes = GROUP_ITEMS;
SelectAutocomplete.propTypes = SELECT_AUTOCOMPLETE_PROP_TYPES;
SelectMenu.propTypes = SELECT_MENU_PROP_TYPES;
SelectMultiple.propTypes = SELECT_MULTIPLE_PROP_TYPES;
SelectControl.propTypes = SELECT_PROP_TYPES;

SelectControl.defaultProps = {
  disabled: false,
  settings: {
    autocomplete: false,
    group: false,
    tree: false,
    options: [],
  },
  size: 'small',
  onChange: () => { },
  onBlur: () => { },
};

export default SelectControl;
