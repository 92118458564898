/**
 * Convert array to object
 *
 * @param {array} array Input array to convert
 * @param {string} key Key to use as identifier
 * @returns {object}
 */
function convertToObject(array, key = 'id') {
  const entries = (array || [])
    .map((item, index) => [
      item[key] || index,
      item,
    ]);

  return Object
    .fromEntries(entries);
}

function shuffle(array) {
  const result = array.slice();

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }

  return result;
}

function sort(property) {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    const aProp = a[property];
    const bProp = b[property];

    const aStr = (aProp || '').toString();
    const bStr = (bProp || '').toString();

    const result = aStr.localeCompare(bStr);

    return result * sortOrder;
  };
}


function dynamicSort() {
  const props = arguments;
  const numberOfProperties = arguments.length;

  if (numberOfProperties === 1) {
    return sort(props[0]);
  }

  return function (obj1, obj2) {
    let i = 0, result = 0;

    while (result === 0 && i < numberOfProperties) {
      result = sort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  };
}

function groupBy(values, keyFinder) {
  return values.reduce((a, b) => {
    const key = typeof keyFinder === 'function'
      ? keyFinder(b)
      : b[keyFinder];

    a[key] = (!a[key]) ? [b] : [...a[key], b];

    return a;
  }, {});
};

/**
 * Filter array by object
 *
 * @param {object} item
 * @param {object} params
 * @returns {array}
 */
function multiFilter(item, params = {}) {
  return !Object
    .entries(params)
    .map(([param, value]) => Array.isArray(value)
      ? value.includes(item[param])
      : item[param] === value)
    .includes(false);
}

/**
 * Paginate array
 *
 * @param {array} array
 * @param {number} pageSize
 * @param {number} page
 * @returns {array}
 */
function paginate(array, pageSize, page) {
  const size = pageSize || array.length;

  return page
    ? array.slice((page - 1) * size, page * size)
    : new Array(Math.ceil(array.length / pageSize))
      .fill(null)
      .map((_, index) => paginate(array, pageSize, index + 1));
}

export {
  convertToObject,
  dynamicSort,
  groupBy,
  multiFilter,
  paginate,
  shuffle,
};
