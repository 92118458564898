import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react';
import { Close } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { pathsToAvoidByAssistantIcon } from 'utils/paths';
import PropTypes from 'prop-types';
import MessagesCard from 'views/Settings/Assistant/MessagesCard';
import { OK_STATUS, STATUS } from 'views/Settings/Assistant/constants';
import Dynamicore, { SERVICES } from 'services/dynamicore';
import Form from 'views/Settings/Assistant/Form';
import { dynamicSort } from 'utils/arrays';
import { getCurrentSessionData } from 'utils/aws/cognito';
import axios from 'utils/axios';
import { populate } from 'utils/strings';
import { LinearProgress } from '@material-ui/core';

const AssistantContext = createContext();

export const useAssistant = () => {
  return useContext(AssistantContext);
};

function AssistantWrapper({ children }) {
  const messagesEndRef = useRef(null);
  const { pathname: path } = useLocation();
  const [thread, setThread] = useState();
  const [message, setMessage] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);


  const contextValue = {
    setShowChat,
    message,
    setMessage,
    file,
    setFile,
    thread,
    setThread,
    isLoading,
    setIsLoading,
    sendMessage: async (replaceMessage = null) => {
      const strMessage = replaceMessage || message;
      if (!strMessage) {
        console.error('no message available');
        return;
      }
      setIsLoading(true);

      if (strMessage) {
        const messageContent = [{
          type: 'text',
          text: {
            value: strMessage,
          },
        }];

        if (file) {
          messageContent.push({
            type: 'file',
            value: {
              name: file.name,
              url: file.url,
            },
          });
        }

        setThread((prev) => {
          const newThread = {
            ...prev,
            messages: [
              ...(prev?.messages || []),
              {
                content: messageContent,
                created_at: new Date().getTime() / 1000,
                role: 'user',
              },
              {
                content: [],
                created_at: new Date().getTime() / 1000,
                role: 'assistant',
              },
            ],
            status: STATUS.SENDING,
          };
          return newThread;
        });
      }

      try {
        const messageResp = await Dynamicore(SERVICES.ASSISTANT_CREATE_MESSAGE).post({
          thread_id: thread?.id,
          content: strMessage,
        });
        setFile(null);

        setThread((prev) => ({
          ...prev,
          runId: messageResp.id,
          status: 'in_progress',
        }));

        localStorage.setItem('thread_runid', messageResp.id);
        if (!replaceMessage) {
          setMessage('');
        }
      } catch (error) {
        setIsLoading(false);
        if (strMessage.trim()) {
          setThread((prev) => ({
            ...prev,
            messages: prev.messages.slice(0, -2),
            status: STATUS.ERROR,
          }));
        }
      }
    },
    async handleCleanThread() {
      try {
        await Dynamicore(SERVICES.ASSISTANT_DELETE_THREAD, {
          threadId: thread?.id,
        }).delete({}, true);
      } catch (error) {
        localStorage.removeItem('thread_id');
        localStorage.removeItem('thread_runid');
        setThread();
      }
    },
  };

  const checkStatus = (data) => {
    if (!data || !data.length) return false;

    const runId = localStorage.getItem('thread_runid');
    const currentRun = data.find((run) => run.id === runId);

    if (currentRun) {
      return currentRun.status === 'in_progress' || currentRun.status === 'queued';
    }

    return data.some((run) => run.status === 'in_progress' || run.status === 'queued');
  };


  const getMessages = async function (threadId) {
    const { data } = (await axios({
      method: 'get',
      url: populate(SERVICES.ASSISTANT_RUNS, {
        threadId,
      }),
    })) ?? {};

    const response = {
      status: data?.message,
    };

    let force_nextRun = true;


    if (checkStatus(data.data)) {
      force_nextRun = false;
      response.status = 'in_progress';
    } else setMessage('');


    if (OK_STATUS.includes(data?.message) && force_nextRun) {
      const { values = [] } = await Dynamicore(SERVICES.ASSISTANT_READ_MESSAGE, {
        threadId,
      }).get();

      response.messages = values.sort(dynamicSort('created_at'));
    } else {
      response.status = 'in_progress';
      response.messages = thread?.messages ?? [];
    }

    return response;
  };

  const loadData = async function () {
    try {
      let data = {};
      const savedThread = localStorage.getItem('thread_id');

      if (savedThread && /^thread_[a-zA-Z0-9]+$/.test(savedThread)) {
        data.id = savedThread;

        const response = await getMessages(savedThread);

        data.lastQuery = new Date();
        data.messages = response.messages;
        data.status = response.status;
      } else {
        data = await Dynamicore(SERVICES.ASSISTANT_THREAD).post();

        if (!data?.id) {
          throw new Error();
        }

        localStorage.setItem('thread_id', data?.id);

        data.lastQuery = new Date();
        data.messages = [];
        data.status = STATUS.OK;
      }

      const { userData } = getCurrentSessionData();

      data.user = {
        ...userData?.UserAttributes,
        username: userData?.Username,
      };

      const lastMessage = [...(data?.messages ?? [])].pop();
      if (lastMessage?.role === 'user') {
        data.messages.push({
          content: [],
          created_at: new Date().getTime() / 1000,
          role: 'assistant',
        });
      }

      setThread(data);

    } catch (error) {
      console.error('Error loading assistant data:', error);
      setThread({
        ...(thread || {}),
        error: error.message,
        lastQuery: new Date().getTime() / 1000,
        status: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let intervalId;

    const checkAndUpdateMessages = async () => {
      const savedThread = localStorage.getItem('thread_id');
      if (!savedThread) return;

      try {
        const response = await getMessages(savedThread);

        setThread((prev) => {
          if (prev.status === response.status &&
            JSON.stringify(prev.messages) === JSON.stringify(response.messages)) {
            return prev;
          }

          if (prev.messages.length > response.messages.length) {
            return prev;
          }

          return {
            ...prev,
            messages: response.messages,
            status: response.status,
          };
        });

        if (response.status === STATUS.COMPLETED) {
          clearInterval(intervalId);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking messages:', error);
        clearInterval(intervalId);
        setIsLoading(false);
      }
    };

    if (thread?.status === STATUS.SENDING || thread?.status === 'in_progress') {
      checkAndUpdateMessages();
      intervalId = setInterval(checkAndUpdateMessages, 2000);

      return () => clearInterval(intervalId);
    }
  }, [thread?.status, thread?.id]);

  useEffect(() => {
    if (!thread || !OK_STATUS.includes(thread?.status)) {
      loadData();
    }
    if (showChat && messagesEndRef.current) {
      const timer = setTimeout(() => {
        messagesEndRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [thread?.messages, showChat]);

  return (
    <AssistantContext.Provider value={contextValue}>
      {children}
      {
        !thread ?
          <LinearProgress />
          :
          <>
            {
              !pathsToAvoidByAssistantIcon.some((p) => p === path) &&
              <>
                {
                  showChat &&
                  <div
                    style={{
                      position: 'absolute',
                      right: '20px',
                      bottom: '20px',
                      padding: '10px',
                      borderRadius: '10px',
                      border: '1px solid #db1c1f',
                      backgroundColor: 'white',
                      Width: '400px',
                      maxWidth: '400px',
                      zIndex: 120,
                    }}>
                    <div
                      onClick={() => setShowChat(false)}
                      style={{
                        cursor: 'pointer',
                        textAlign: 'right',
                      }}
                    >
                      <Close />
                    </div>
                    <MessagesCard
                      messagesEndRef={messagesEndRef}
                      sendMessage={contextValue.sendMessage}
                      setMessage={setMessage}
                      thread={thread}
                    />
                    <Form
                      isLoading={isLoading}
                      message={message}
                      setMessage={setMessage}
                      sendMessage={contextValue.sendMessage}
                      thread={thread}
                      file={file}
                      setFile={setFile}
                    />
                  </div>
                }
              </>
            }
          </>
      }
    </AssistantContext.Provider>
  );
};

export default AssistantWrapper;

AssistantWrapper.propTypes = {
  children: PropTypes.any,
};

