import React, { useState, useRef } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  Add,
  AttachFile,
  NoteAdd,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useAssistant } from 'components/Wrappers/AssistantWrapper';
import { useTranslation } from 'react-i18next';

function FormIcon(props) {
  const { openFileModal } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.assistant',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const open = Boolean(anchorEl);
  const { handleCleanThread } = useAssistant();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={t('more')}>
        <Button
          ref={buttonRef}
          color='primary'
          onClick={handleClick}
          sx={{
            borderRadius: '100%',
          }}
        >
          <Add />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          marginBottom: 1,
        }}
      >
        <MenuItem onClick={() => {
          openFileModal();
          handleClose();
        }}>
          <AttachFile
            style={{
              marginRight: 5,
            }} />
          {t('attach')}
        </MenuItem>
        <MenuItem onClick={() => {
          handleCleanThread();
          handleClose();
        }}>
          <NoteAdd
            style={{
              marginRight: 5,
            }} />
          {t('new_conversation')}
        </MenuItem>
      </Menu>
    </div>
  );
}

FormIcon.propTypes = {
  openFileModal: PropTypes.func.isRequired,
};

export default FormIcon;
