import {
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  FormControl,
  FormHelperText,
  makeStyles,
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  Divider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  //FolderOpenOutlined as FolderOpenIcon,
  Group as GroupIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  Save as SaveIcon,
  Label as LabelIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  TextFields as TextFieldsIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from '@material-ui/icons';
import {
  Document,
  Page,
  pdfjs,
} from 'react-pdf';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Dynamicore, { SERVICES } from 'services/dynamicore';
import { dynamicSort } from 'utils/arrays';
import {
  DataTable,
  Modal,
  Header,
  DynamicForm,
  ButtonGroup,
} from 'components';
import { useTable } from 'hooks';
import { useSnackbar } from 'notistack';
import {
  addSignerFormSchema,
  addSignerFormSchemaClient,
  addSignerFormSchemaRelationship,
} from 'validations/schema/forms';
import { SelectControl } from 'components/DynamicForm/controls';
const useStyles = makeStyles((theme) => ({
  label: {
    background: theme.palette.background.default,
  },
  pdfContainer: {
    marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    padding: '0px',
    width: '630px', //612px
    height: '810px', //792px
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5', // Fondo más claro
    borderRadius: theme.spacing(1.5), // Bordes redondeados
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Sombra ligera
  },
  pdfViewer: {
    width: '100%',
    height: '100%',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    gap: theme.spacing(2),
  },
  paginationButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    border: 'none',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    transition: 'background 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: '#ccc',
      cursor: 'not-allowed',
    },
  },
  addSignatureButton: {
    background: '#007bff',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background 0.3s',
  },
  cancelButton: {
    background: '#dc3545',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background 0.3s',
  },
  boxContainer: {
    borderLeft: '1px solid #ccc',
    width: '30%',
    height: '100%',
    position: 'relative',
    top: 0,
    right: 0,
    zIndex: 1300,
    boxShadow: 1,
    padding: theme.spacing(2),
    maxHeight: '125vh',
    overflowY: 'auto',
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    },
  },
  uploadContainer: {
    border: '2px dashed #ccc',
    padding: '30px',
    textAlign: 'center',
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  uploadIcon: {
    fontSize: '120px',
    color: theme.palette.primary.main,
    marginBottom: '16px',
  },
  uploadLabel: {
    ...theme.typography.h5,
    margin: '8px 0',
  },
  uploadText: {
    color: '#777',
    ...theme.typography.body2,
  },
  uploadTextAlt: {
    color: '#333',
    ...theme.typography.body1,
  },
  errorText: {
    ...theme.typography.caption,
    color: 'red',
    marginTop: '12px',
  },
  labelBar: {
    ...theme.typography.body2,
  },
  helper: {
    ...theme.typography.body1,
    textAlign: 'center',
    marginBottom: '16px',
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

function FileAndHtmlControl(props) {
  const classes = useStyles();
  const [text, setText] = useState('');
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.documents_templates_edit',
  });
  const [currentError, setCurrentError] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [showSigners, setShowSigners] = useState(false);
  const [showTypeSigners, setShowTypeSigners] = useState(false);
  const [signers, setSigners] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [data, setData] = useState(null);
  const [draggingSignature, setDraggingSignature] = useState(null);
  const dragOffset = useRef({
    relative_position_x: 0,
    relative_position_y: 0,
  });
  const [resizingSignature, setResizingSignature] = useState(null);
  const resizeOffset = useRef({
    relative_position_x: 0,
    relative_position_y: 0,
  });
  const [labels, setLabels] = useState(props?.defaultSignatures?.labels);
  const [labelsMatch, setLabelsMatch] = useState(props?.defaultSignatures?.labelsMatch);
  const [newLabel, setNewLabel] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [dimensions, setDimensions] = useState({});
  const [modal, setModal] = useState();
  const [modal2, setModal2] = useState();
  const [table, setTable] = useState();
  const [company, setCompany] = useState();
  const { generateTable } = useTable({
    translate: t,
  });
  const HEADERS = [
    {
      hidden: process.env.NODE_ENV === 'production',
      name: 'id',
    },
    {
      name: 'name',
    },
    {
      name: 'email',
    },
    {
      name: 'telefono',
    },
    {
      name: 'description',
    },
    {
      name: 'type',
    },
    {
      name: 'cantidad',
    },
  ];
  const SIGNERS_BUTTON_GROUP = [
    {
      label: t('actions.list'),
      value: 'list',
    },
    {
      label: t('actions.create_signer'),
      value: 'add',
    },
  ];
  const [currentView, setCurrentView] = useState('list');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [typeAddSigner, setTypeAddSigner] = useState();
  const typeMap = {
    1: 'default',
    2: 'client',
    3: 'relations',
  };
  const selectorTypes = [
    {
      id: 1,
      name: t('forms.selectors.default'), // mandar siempre el id 0
    },
    {
      id: 2,
      name: t('forms.selectors.client'), // obtener de plantillas de personas
    },
    {
      id: 3,
      name: t('forms.selectors.relationships'), // obtener de selector de relaciones de clientes
    },
  ];
  const [selectorType, setSelectorType] = useState(t('forms.selectors.select'));
  const [selectorClients, setSelectorClients] = useState();
  const [selectorClient, setSelectorClient] = useState(t('forms.selectors.select'));
  const [selectorRelationships, setSelectorRelationships] = useState();
  const [selectorRelationShip, setSelectorRelationShip] = useState(t('forms.selectors.select'));
  const [isProcessing, setIsProcessing] = useState();
  const [valuesClient, setValuesClient] = useState();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const stepsQuestion1 = [
    t('messages.steep_one'),
    t('messages.steep_two'),
    t('messages.steep_three'),
    t('messages.steep_four'),
  ];
  const stepsQuestion2 = [
    {
      label: t('messages.step_tool_access'),
      icon: <LabelIcon />,
    },
    { 
      label: t('messages.step_find_add_button'),
      icon: <SearchIcon />,
    },
    { 
      label: t('messages.step_enter_label_name'),
      icon: <TextFieldsIcon />,
    },
    { 
      label: t('messages.step_press_enter_add'), 
      icon: <AddCircleOutlineIcon />,
    }];

  const stepsCompleted = [
    pdfFile !== null,
    labels && Object.keys(labels).length > 0,
    labels && Object.values(labels).every((value) => value !== ''),
    signatures && Object.keys(signatures).length > 0,
  ];

  const {
    disabled,
    error,
    events,
    label,
    name,
    size,
    settings,
    onChange,
    defaultFile,
    defaultFileName,
    defaultSignatures,
  } = props;
  const {
    accept = [],
    base64 = false,
    multiple = false,
  } = settings;

  let inputFile;


  const fetchData = async () => {
    const result = await Dynamicore(SERVICES.DOCUMENTS_TEMPLATES_CLIENTS).get({
      type: 'all',
    });

    if (result.values && Array.isArray(result.values)) {
      let idCounter = 1;
      const allFields = result.values.flatMap((value) => {
        const [firstEntry] = Object.entries(value);
        const fields = firstEntry?.[1]?.fields || [];
        const groupName = value.name;
        return fields.map((field) => ({
          id: idCounter++,
          val: field.value,
          name: field.name,
          groupBy: groupName,
        }));
      });
      const sortedFields = allFields.sort(dynamicSort('groupBy', 'name'));
      setData(sortedFields);
    }
  };

  //Lee el PDF y obtiene las etiquetas(Campos)
  const getTextFromPDF = async (pdfFile) => {
    const pdf = await pdfjs.getDocument(pdfFile).promise;
    let extractedStructures = [];

    const regex = /{{\s*(.*?)\s*}}/g;

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(' ');
      const sanitizedText = pageText.replace(/\{\s+/g, '{').replace(/\s+\}/g, '}');

      const matches = sanitizedText.match(regex)?.map((match) => {
        return match.replace(/\s+/g, '');
      });

      if (matches) {
        extractedStructures = [...extractedStructures, ...matches];
      }
    }

    const uniqueLabels = [...new Set(extractedStructures)];
    const labelObject = uniqueLabels.reduce((acc, label) => {
      acc[label] = '';
      return acc;
    }, {});

    setLabels(labelObject);
    setLabelsMatch(labelObject);
  };

  //Carga de archivo por defecto
  const loadDefaultFile = async function () {
    if (defaultFile !== undefined) {
      const renamedFile = new File([defaultFile], defaultFileName, {
        type: defaultFile.type,
        lastModified: defaultFile.lastModified,
      });

      setText(defaultFileName);

      const target = {
        name,
        value: renamedFile,
      };
      const fileUrl = URL.createObjectURL(renamedFile);
      setPdfFile(fileUrl);
      onChange({
        target,
      });

      if (events?.onChange) {
        events.onChange(target);
      }
    }
  };

  //Carga de firmantes
  const loadSigners = async function () {
    const {
      values = [],
      total = 0,
    } = await Dynamicore(SERVICES.DOCUMENTS_TEMPLATES_SIGNERS).get();

    setSigners(values.sort(dynamicSort('name')));

    let updateValues = values;

    if (values.length > 0) {
      updateValues = updateValuesFromMapeo(updateValues);
    }

    setTable({
      rows: updateValues.sort(dynamicSort('name')),
      total,
    });

    const company = JSON.parse(localStorage.getItem('company'));
    setCompany(company);
  };

  function updateValuesFromMapeo(values) {
    return values.map((obj) => {
      if (obj.mapeo && Object.keys(obj.mapeo).length > 0) {
        obj.name = obj.name.trim() === '' ? obj.mapeo.displayNameName || obj.name : obj.name;
        obj.telefono = obj.telefono.trim() === '' ? obj.mapeo.displayNameTelefono || obj.telefono : obj.telefono;
        obj.email = obj.email.trim() === '' ? obj.mapeo.displayNameEmail || obj.email : obj.email;
      }
      return obj;
    });
  };

  const loadClients = async function () {
    const {
      values = [],
      //total = 0,
    } = await Dynamicore(SERVICES.PEOPLE_TYPES).get();

    setSelectorClients(values.sort(dynamicSort('name')));
  };

  const loadRelationships = async function () {
    const {
      values = [],
      //total = 0,
    } = await Dynamicore(SERVICES.PEOPLE_RELATIONSHIP).get({
      enabled: true,
    });
    //{
    //  enabled: true,
    //}

    setSelectorRelationships(values.sort(dynamicSort('name')));
  };

  const loadSignatures = () => {
    if (defaultSignatures !== undefined) {
      if (defaultSignatures.signers.length > 0) {
        defaultSignatures.signers.forEach((signer) => {
          signer.relative_size_x = ((signer.relative_size_x) * defaultSignatures.dimensions.x) / 100;
          signer.relative_size_y = ((signer.relative_size_y) * defaultSignatures.dimensions.y) / 100;
          signer.relative_position_x = ((signer.relative_position_x) * defaultSignatures.dimensions.x) / 100;
          signer.relative_position_y = ((signer.relative_position_y) * defaultSignatures.dimensions.y) / 100;
        });
        setSignatures((prev) => [...prev, ...defaultSignatures.signers]);
        setDimensions(defaultSignatures.dimensions);
      }
    }
  };

  //Carga de archivo por defecto
  useEffect(() => {
    loadDefaultFile();
  }, [defaultFile]);

  useEffect(() => {
    loadRelationships();
    loadClients();
    loadSigners();
    loadSignatures();
    fetchData();
  }, []);

  useEffect(() => {
    if (props.defaultFile) {
      setOpenSidebar(true);
    }
  }, [props.defaultFile]);

  const handleAddLabel = () => {
    if (newLabel.trim() !== '' && !labels[newLabel]) {
      setLabels((prev) => ({
        ...prev,
        [newLabel]: '',
      }));
      setLabelsMatch((prev) => ({
        ...prev,
        [newLabel]: '',
      }));
      setNewLabel('');
      setIsAdding(false);
    }
  };
  //Detectar si se ha seleccionado un archivo
  const handleSelectFile = function () {
    inputFile.click();
  };

  //Cargar archivo
  const handleFileChange = async function ({ target: { files } }) {
    setCurrentError(false);
    const names = [];
    let filesOutput = [];
    const validExt = accept.map((_) => _?.toLowerCase());

    if (!base64) {
      filesOutput = files;
    }

    for await (const file of files) {
      const [, ext] = file
        .name
        .split('.')
        .map((_) => _?.toLowerCase());

      names.push(file.name);

      if (base64) {
        filesOutput.push(await toBase64(file));
      }

      if (!currentError && validExt.length) {
        setCurrentError(validExt.includes(ext) ? '' : 'Invalid file type');
      }

      if (file.type === 'application/pdf') {
        const fileUrl = URL.createObjectURL(file);
        setPdfFile(fileUrl);
        // Obtener el texto del PDF
        await getTextFromPDF(fileUrl);
        handleSidebarToggle(0, true);
      } else {
        setPdfFile(null);
      }
    }

    setText(names.join(', '));

    const target = {
      name,
      value: filesOutput[0],
    };

    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }

    setSignatures([]);
  };

  //Muestra el menu de opciones de firma al hacer clic en el PDF
  const handlePdfClick = (event) => {
    if (draggingSignature !== null) return;

    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    if (dimensions.x === undefined || dimensions.y === undefined) {
      setDimensions({
        x: rect.width,
        y: rect.height,
      });
    }

    setMenuPosition({
      relative_position_x: x,
      relative_position_y: y,
    });
  };

  //Paginación de PDF (anterior)
  const handleBack = () => {
    setPageNumber((prev) => Math.max(prev - 1, 1));
  };

  //Paginación de PDF (siguiente)
  const handleNext = () => {
    setPageNumber((prev) => Math.min(prev + 1, numPages));
  };

  //Manejo de opciones del menú de firma
  const handleMenuOption = (option, signer = null) => {
    if (option === 'addSignature') { //Mostrar tipos de firmantes
      setShowTypeSigners(true);
      setShowSigners(false); // Mostrar opciones de firmantes
    } else if (option === 'cancel') {
      setMenuPosition(null);
      setShowTypeSigners(false);
      setShowSigners(false);
    } else if (option === 'selectTypeSigner') {
      setShowTypeSigners(false);
      setShowSigners(true);
      setTypeAddSigner(signer.id);
    } else if (option === 'selectSigner' && signer) {
      setSignatures((prev) => {
        return [...prev, {
          page: pageNumber,
          type: 'signature',
          signer_id: signer.id, // Mantén una referencia al firmante
          relative_size_x: 90,
          relative_size_y: 40,
          relative_position_x: menuPosition.relative_position_x,
          relative_position_y: menuPosition.relative_position_y,
          id: uuidv4(), // Genera un identificador único para cada firma
          name: signer.name,
        }];
      });

      setMenuPosition(null); // Ocultar el menú después de agregar la firma
      setShowSigners(false);
      setShowTypeSigners(false);
    }
  };

  //Eliminar firma
  const handleRemoveSignature = (id) => {
    setSignatures((prev) => prev.filter((signature) => signature.id !== id));

    setMenuPosition(null);
    setShowSigners(false);
    setShowTypeSigners(false);
  };

  //Manejo de arrastre de firma
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (draggingSignature !== null) {
        setSignatures((prevSignatures) =>
          prevSignatures.map((signature) =>
            signature.id === draggingSignature
              ? {
                ...signature,
                relative_position_x: e.clientX - dragOffset.current.relative_position_x,
                relative_position_y: e.clientY - dragOffset.current.relative_position_y,
              }
              : signature,
          ),
        );
      }
    };

    const handleMouseUp = () => {
      setDraggingSignature(null);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [draggingSignature]);

  //Manejo de error
  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  //Manejo de redimensionamiento de firma
  const handleResizeMouseDown = (e, signatureId) => {
    e.stopPropagation();
    setResizingSignature(signatureId);
    resizeOffset.current = {
      relative_position_x: e.clientX,
      relative_position_y: e.clientY,
    };
  };

  //Descargar PDF
  const handleDownloadPdf = () => {
    if (pdfFile) {
      const link = document.createElement('a');
      link.href = pdfFile;
      link.download = text; // Puedes cambiar el nombre del archivo aquí
      link.click();
    }
  };

  function cleanString(str) {
    return str
      .replace(/{{\s*([\w\s.]+)\s*}}/g, '$1')
      .replace(/^[\w]+\./, '')
      .replace(/\s+/g, ' ');
  }
  //Cerrar modal
  const handleModalClose = function () {
    setModal();
    setSelectorType(t('forms.selectors.select'));
    setSelectorClient(t('forms.selectors.select'));
    setSelectorRelationShip(t('forms.selectors.select'));
  };

  //Manejo de redimensionamiento de firma
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (resizingSignature !== null) {
        setSignatures((prevSignatures) =>
          prevSignatures.map((signature) =>
            signature.id === resizingSignature
              ? {
                ...signature,
                relative_size_x: Math.max(90, signature.relative_size_x + (e.clientX - resizeOffset.current.relative_position_x)),
                relative_size_y: Math.max(40, signature.relative_size_y + (e.clientY - resizeOffset.current.relative_position_y)),
              }
              : signature,
          ),
        );
        resizeOffset.current = {
          relative_position_x: e.clientX,
          relative_position_y: e.clientY,
        };
      }
    };

    const handleMouseUp = () => {
      setResizingSignature(null);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizingSignature]);

  const handleSubmitData = async function (data) {
    setIsLoading(true);
    let message, variant = 'success';

    try {

      if (!data?.description) {
        data.description = ' ';
      }

      data.company = company.id;
      data.mapeo = {};

      if (selectorType === 1) {
        data.cantidad = 1;
        data.type = 'default';
        data.type_id = -1;
      } else if (selectorType === 2) {
        data.mapeo = {
          name: data?.name,
          email: data?.email,
          telefono: data?.telefono,
          displayNameName: valuesClient.find((item) => item.id === data?.name)?.name,
          displayNameEmail: valuesClient.find((item) => item.id === data?.email)?.name,
          displayNameTelefono: valuesClient.find((item) => item.id === data?.telefono)?.name,
        };
        data.name = ' ';
        data.email = ' ';
        data.telefono = ' ';
        data.cantidad = 1;
        data.type = 'client';
        data.type_id = selectorClient;
      } else if (selectorType === 3) {
        data.mapeo = {
          name: data?.name,
          email: data?.email,
          telefono: data?.telefono,
          displayNameName: valuesClient.find((item) => item.id === data?.name)?.name,
          displayNameEmail: valuesClient.find((item) => item.id === data?.email)?.name,
          displayNameTelefono: valuesClient.find((item) => item.id === data?.telefono)?.name,
        };
        data.name = ' ';
        data.email = ' ';
        data.telefono = ' ';
        data.cantidad = 1;
        data.type = 'relations';
        data.type_id = selectorRelationShip;
      }

      await Dynamicore(SERVICES.DOCUMENTS_TEMPLATES_SIGNERS).post(data);

      setSelectorType(t('forms.selectors.select'));
      setSelectorClient(t('forms.selectors.select'));
      setSelectorRelationShip(t('forms.selectors.select'));

      loadSigners();
      setCurrentView('list');
      message = t('forms.messages.signer_saved');
    } catch (e) {
      message = e.message || t('forms.messages.signer_saving_error');
      variant = 'error';
    } finally {
      enqueueSnackbar(message, {
        variant,
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };

  const handleRemoveSigner = async function (id) {
    let message, variant = 'success';

    try {
      await Dynamicore(SERVICES.DOCUMENTS_TEMPLATES_SIGNERS).delete({
        id,
      }, true);

      message = t('forms.messages.signer_deleted');

      loadSigners();
      setModal2();
    } catch (error) {
      message = t('forms.messages.signer_deleting_error');
      variant = 'error';
    } finally {
      enqueueSnackbar(message, {
        variant,
      });
    }
  };

  const handleSelectorType = function (values) {
    setIsProcessing(true);

    const matchingConfig = selectorTypes.find(({ id }) => id === parseInt(values.value, 10));
    if (matchingConfig) {
      setSelectorType(matchingConfig?.id);
      setSelectorClient(t('forms.selectors.select'));
      setSelectorRelationShip(t('forms.selectors.select'));
    } else {
      setSelectorType(t('forms.selectors.select'));
      setSelectorClient(t('forms.selectors.select'));
      setSelectorRelationShip(t('forms.selectors.select'));
    }

    setTimeout(() => {
      setIsProcessing(false);
    }, 500);
  };

  const replaceKeyInArray = function (array, oldKey, newKey) {
    return array.map((obj) => {
      if (obj.hasOwnProperty(oldKey)) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
      return obj;
    });
  };

  function filterValidClients(array) {
    if (!Array.isArray(array)) {
      return [];
    }

    return array.filter((item) =>
      item &&
      typeof item === 'object' &&
      item.hasOwnProperty('id') &&
      item.hasOwnProperty('name') &&
      item.id !== undefined &&
      item.id !== null,
    );
  }

  const handleSelectorClient = async function (values) {
    setIsProcessing(true);

    const matchingConfig = selectorClients.find(({ id }) => id === parseInt(values.value, 10));

    if (matchingConfig) {
      setSelectorClient(matchingConfig?.id);

      try {
        const data = await Dynamicore(SERVICES.DOCUMENTS_TEMPLATES_CLIENTS).get({
          type: 'client',
          type_id: matchingConfig?.id,
        });

        const aux = replaceKeyInArray(data?.values, 'value', 'id');

        setValuesClient(aux.sort(dynamicSort('name')));
      } catch (error) {
        console.error('Error al cargar los datos del template:', error);
        return;
      }
    } else {
      setSelectorClient(t('forms.selectors.select'));
    }
    setIsProcessing(false);
  };

  const handleSelectorRelationships = async function (values) {
    setIsProcessing(true);

    const matchingConfig = selectorRelationships.find(({ id }) => id === parseInt(values.value, 10));
    if (matchingConfig) {
      setSelectorRelationShip(matchingConfig?.id);

      try {
        const data = await Dynamicore(SERVICES.DOCUMENTS_TEMPLATES_CLIENTS).get({
          type: 'relationship',
          type_id: matchingConfig?.id,
        });
        const allItems = data?.values.reduce((acc, item) => {
          Object.values(item).forEach((value) => {
            acc.push(...value);
          });
          return acc;
        }, []);
        const aux = replaceKeyInArray(allItems, 'value', 'id');
        setValuesClient(aux.sort(dynamicSort('name')));
      } catch (error) {
        enqueueSnackbar('Error al cargar los datos del template:', error);
        return;
      }
    } else {
      setSelectorRelationShip(t('forms.selectors.select'));
    }
    setIsProcessing(false);
  };
  function findValById(id) {
    const item = data.find((entry) => entry.id === id);
    return item ? item.val : null;
  }

  const handleSidebarToggle = (index, flag = false) => {
    setTabIndex(index); 
    setOpenSidebar(flag);
  };

  useEffect(() => {
    localStorage.setItem('signatures', JSON.stringify(signatures));
    localStorage.setItem('dimensions', JSON.stringify(dimensions));
    localStorage.setItem('selectedLabels', JSON.stringify(labels));
    localStorage.setItem('selectedLabelsMatch', JSON.stringify(labelsMatch));
  }, [signatures, labels]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
        }}
      >         
        <Box
          sx={{
            flexGrow: 1,
            transition: 'margin-right 0.3s ease',
            marginRight: openSidebar ? '16px' : '0px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#f1f3f4',
              borderRadius: '8px',
              boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
              padding: 1,
            }}
          >

            {/* Barra de herramientas */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                padding: 1,
              }}
            >
              {pdfFile && (
                <>
                  <IconButton 
                    onClick={() => {
                      handleSelectFile();
                    }}
                    title={t('actions.upload_pdf')}
                  >
                    <CloudUploadIcon />
                  </IconButton>  
                  <IconButton 
                    disabled={!pdfFile} 
                    onClick={() => {
                      handleDownloadPdf();
                    }}
                    title={t('actions.download_pdf')}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <input
                    type="file"
                    accept=".pdf"
                    hidden
                    ref={inputFile}
                    onChange={handleFileChange}
                  />
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              <Box onClick={() => handleSidebarToggle(0, true)} style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}>
                <IconButton>
                  <LabelIcon />
                </IconButton>
                <Typography variant="caption" className={classes.labelBar }>{t('actions.label')}</Typography>
              </Box>
              <Box onClick={() => setModal({
                title: 'Firmantes',
                open: true,
                maxWidth: 'lg',
                view: 'addSigner',
              })}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}>
                <IconButton>
                  <GroupIcon />
                </IconButton>
                <Typography variant="caption" className={classes.labelBar }>{t('actions.signers')}</Typography>
              </Box>
              <Box onClick={() => handleSidebarToggle(1, true)} style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}>
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
                <Typography variant="caption" className={classes.labelBar }>{t('actions.help')}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{
            marginTop: 16,
          }}>
            <FormControl
              disabled={disabled}
              error={currentError}
              fullWidth
              size={size}
              variant="outlined"
            >  
              {!pdfFile && (
                <div className={classes.uploadContainer} onClick={handleSelectFile}>
                  <input
                    accept={accept.map((_) => `.${_}`).join()}
                    hidden
                    multiple={multiple}
                    onChange={handleFileChange}
                    ref={(input) => inputFile = input}
                    type="file"
                  />
                  <CloudUploadOutlinedIcon className={classes.uploadIcon} />
                  <p className={classes.uploadLabel}>{label}</p>
                  {text ? (
                    <p className={classes.uploadTextAlt}>{text}</p>
                  ) : (
                    <p className={classes.uploadText}>Presiona para subir un archivo</p>
                  )}
                  {currentError && <p className={classes.errorText}>{currentError}</p>}
                </div>
              )}
              {pdfFile && (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={10} md={10}>
                    <input
                      accept={accept.map((_) => `.${_}`).join()}
                      hidden
                      multiple={multiple}
                      onChange={handleFileChange}
                      ref={(input) => inputFile = input}
                      type="file"
                    />
                    {currentError && <FormHelperText>{currentError}</FormHelperText>}
                  </Grid>
                </Grid>
              )}
            </FormControl>
          </Box>
          {/*Muestra el pdf*/}
          {pdfFile && (
            <Grid container justifyContent="center">
              <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <div className={classes.pdfContainer}>
                  <Document
                    file={pdfFile}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page
                      pageNumber={pageNumber}
                      className={classes.pdfViewer}
                      onClick={handlePdfClick}
                      onMouseMove={(e) => {
                        if (draggingSignature !== null) {
                          e.preventDefault(); // Evitar la selección de texto mientras se arrastra
                          const rect = e.target.getBoundingClientRect();
                          const x = e.clientX - rect.left;
                          const y = e.clientY - rect.top;

                          setSignatures((prev) =>
                            prev.map((sig) =>
                              sig.id === draggingSignature ? {
                                ...sig,
                                relative_position_x: x,
                                relative_position_y: y,
                              } : sig,
                            ),
                          );
                        }
                      }}
                      onMouseUp={() => setDraggingSignature(null)}
                    >
                      {menuPosition && (
                        <div
                          style={{
                            position: 'absolute',
                            left: `${menuPosition.relative_position_x}px`,
                            top: `${menuPosition.relative_position_y}px`,
                            background: 'white',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
                            padding: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            zIndex: 10,
                          }}
                          onClick={(e) => e.stopPropagation()} // Evita que se cierre al hacer clic dentro del menú
                        >
                          {!showTypeSigners && !showSigners ? (
                            <>
                              <p
                                style={{
                                  margin: '0',
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                  backgroundColor: '#bfbfbf',
                                  padding: '8px',
                                  borderRadius: '8px',
                                }}
                              >{t('actions.options')}</p>
                              <button
                                type="button"
                                onClick={() => handleMenuOption('addSignature')}
                                className={classes.addSignatureButton}
                              >
                                {t('actions.add_signature')}
                              </button>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMenuOption('cancel');
                                }}
                                className={classes.cancelButton}
                              >
                                {t('actions.cancel')}
                              </button>
                            </>
                          ) : showTypeSigners && !showSigners ? (
                            <>
                              <p
                                style={{
                                  margin: '0',
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                  backgroundColor: '#bfbfbf',
                                  padding: '8px',
                                  borderRadius: '8px',
                                }}
                              >{t('actions.types_signers')}</p>
                              {selectorTypes.map((type) => (
                                <button
                                  type="button"
                                  key={type.id}
                                  onClick={() => handleMenuOption('selectTypeSigner', type)}
                                  className={classes.addSignatureButton}
                                >
                                  {type.name}
                                </button>
                              ))}
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMenuOption('cancel');
                                }}
                                className={classes.cancelButton}
                              >
                                {t('actions.cancel')}
                              </button>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  margin: '0',
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                  backgroundColor: '#bfbfbf',
                                  padding: '8px',
                                  borderRadius: '8px',
                                  textAlign: 'center',
                                }}
                              >{t('actions.select_signer')}</p>
                              {signers.filter((signer) => signer.type === typeMap[typeAddSigner]).length > 0 ? (
                                signers.filter((signer) => signer.type === typeMap[typeAddSigner])
                                  .map((signer) => (
                                    <button
                                      type="button"
                                      key={signer.id}
                                      onClick={() => handleMenuOption('selectSigner', signer)}
                                      className={classes.addSignatureButton}
                                    >
                                      {signer.name}
                                    </button>
                                  ))
                              ) : (
                                <p style={{
                                  fontSize: '16px',
                                  fontFamily: 'Roboto',
                                  textAlign: 'center',
                                }}>
                                  {t('messages.no_signers_available')}
                                </p>
                              )}
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowSigners(false); // Volver al menú anterior
                                  setShowTypeSigners(true);
                                }}
                                className={classes.cancelButton}
                              >
                                {t('actions.back')}
                              </button>
                            </>
                          )}
                        </div>
                      )}
                      {signatures.filter((signature) => signature.page === pageNumber).map((signature) => (
                        <div
                          key={signature.id}
                          style={{
                            position: 'absolute',
                            left: `${signature.relative_position_x}px`,
                            top: `${signature.relative_position_y}px`,
                            width: `${signature.relative_size_x || 90}px`,
                            height: `${signature.relative_size_y || 40}px`,
                            border: '2px dashed #007bff',
                            backgroundColor: 'rgba(0, 123, 255, 0.1)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '12px',
                            color: '#007bff',
                            cursor: 'grab',  // Indicar que es arrastrable
                            zIndex: draggingSignature === signature.id ? 20 : 10,
                            userSelect: 'none', // Evitar la selección de texto
                          }}
                          onMouseDown={(e) => {
                            setDraggingSignature(signature.id);
                            dragOffset.current = {
                              relative_position_x: e.clientX - signature.relative_position_x,
                              relative_position_y: e.clientY - signature.relative_position_y,
                            };
                          }}
                          onClick={(e) => e.stopPropagation()} // Evitar que se cierre al hacer clic en la firma
                        >
                          {signature.name}
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '0',
                              right: '0',
                              width: '10px',
                              height: '10px',
                              backgroundColor: '#007bff',
                              cursor: 'se-resize',
                            }}
                            onMouseDown={(e) => handleResizeMouseDown(e, signature.id)}
                          />
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSignature(signature.id);
                            }}
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-10px',
                              backgroundColor: '#85929e',
                              color: 'white',
                              border: 'none',
                              borderRadius: '50%',
                              width: '18px',
                              height: '18px',
                              fontSize: '12px',
                              cursor: 'pointer',
                            }}
                          >
                            X
                          </button>
                        </div>
                      ))}
                    </Page>
                  </Document>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.paginationContainer}>
                <button
                  type="button"
                  className={classes.paginationButton}
                  onClick={handleBack}
                  disabled={pageNumber <= 1}
                >
                  {t('actions.back_page')}
                </button>
                <span>{t('actions.page')} {pageNumber} {t('actions.of')} {numPages}</span>
                <button
                  type="button"
                  className={classes.paginationButton}
                  onClick={handleNext}
                  disabled={pageNumber >= numPages}
                >
                  {t('actions.next_page')}
                </button>
              </Grid>
            </Grid>
          )}

          <Modal
            title={modal?.title}
            open={modal?.open}
            maxWidth={modal?.maxWidth}
            events={{
              onClose: () => handleModalClose(),
            }}
          >
            {modal?.view === 'addSigner' && (<>
              <ButtonGroup
                actions={SIGNERS_BUTTON_GROUP}
                onChange={
                  (value) => {
                    setCurrentView(value);
                    setSelectorType(t('forms.selectors.select'));
                    setSelectorClient(t('forms.selectors.select'));
                    setSelectorRelationShip(t('forms.selectors.select'));
                  }
                }
                pb={3}
                pt={2}
                value={currentView}
                width={220}
                color={'secondary'}
              />

              <Header
                actions={[
                  {
                    color: 'secondary',
                    form: 'frmAddSigner',
                    icon: <SaveIcon />,
                    label: t('actions.save'),
                    type: 'submit',
                    processing: isLoading,
                    hidden: currentView !== 'add',
                  },
                ]}
                breadcrumb={false}
                title={currentView === 'add' ? t('actions.create_signer') : t('actions.list_signers')}
              />

              {currentView === 'list' && (
                <DataTable
                  actions={[
                    {
                      color: 'error',
                      icon: <DeleteTwoToneIcon />,
                      title: t('table.actions.delete_signer'),
                      fn: function ({ data }) {
                        setModal2({
                          open: true,
                          data,
                        });
                      },
                    },
                  ]}
                  total={table?.total ?? 0}
                  {...generateTable(
                    HEADERS.filter((header) => !header?.hidden),
                    table?.rows,
                  )}
                  mt={2}
                />
              )}

              {currentView === 'add' && (<>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <SelectControl
                      onChange={function ({ target }) {
                        handleSelectorType(target);
                      }}
                      label={t('forms.signers.fields.type')}
                      settings={{
                        emptyElement: t('forms.selectors.select'),
                        options: selectorTypes.map(({ id, name }) => ({
                          id: id,
                          name: name,
                        })),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {selectorType === 2 && (
                      <SelectControl
                        onChange={function ({ target }) {
                          handleSelectorClient(target);
                        }}
                        key={selectorType}
                        label={t('forms.signers.fields.templete')}
                        settings={{
                          emptyElement: t('forms.selectors.select'),
                          options: selectorClients.map(({ id, name }) => ({
                            id: id,
                            name: name,
                          })),
                        }}
                      />
                    )}
                    {selectorType === 3 && (
                      <SelectControl
                        onChange={function ({ target }) {
                          handleSelectorRelationships(target);
                        }}
                        key={selectorType}
                        label={t('forms.signers.fields.relationship')}
                        settings={{
                          emptyElement: t('forms.selectors.select'),
                          options: selectorRelationships.map(({ id, name }) => ({
                            id: id,
                            name: name,
                          })),
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                {isProcessing === false &&
              <DynamicForm
                fields={[
                  {
                    display: {
                      breakpoints: {
                        md: 6,
                        sm: 12,
                        xs: 12,
                      },
                    },
                    type: 'divider',
                    name: 'divider1',
                  },
                  {
                    display: {
                      breakpoints: {
                        md: 6,
                        sm: 12,
                        xs: 12,
                      },
                    },
                    label: t('forms.signers.fields.name'),
                    name: 'name',
                    type: (selectorType !== t('forms.selectors.select')) && ((selectorType === 1)) ? 'text' : ((selectorType === 2 && selectorClient !== t('forms.selectors.select')) ? 'select' : ((selectorType === 3 && selectorRelationShip !== t('forms.selectors.select')) ? 'select' : 'hidden')),
                    settings: {
                      autocomplete: (valuesClient || [])?.length > 10,
                      emptyElement: t('forms.selectors.select'),
                      options: (filterValidClients(valuesClient) || []),
                    },
                  },
                  {
                    display: {
                      breakpoints: {
                        md: 6,
                        sm: 12,
                        xs: 12,
                      },
                    },
                    label: t('forms.signers.fields.email'),
                    name: 'email',
                    type: (selectorType !== t('forms.selectors.select')) && ((selectorType === 1)) ? 'text' : ((selectorType === 2 && selectorClient !== t('forms.selectors.select')) ? 'select' : ((selectorType === 3 && selectorRelationShip !== t('forms.selectors.select')) ? 'select' : 'hidden')),
                    settings: {
                      autocomplete: (valuesClient || []).length > 10,
                      emptyElement: t('forms.selectors.select'),
                      options: (filterValidClients(valuesClient) || []),
                    },
                  },
                  {
                    display: {
                      breakpoints: {
                        md: 6,
                        sm: 12,
                        xs: 12,
                      },
                    },
                    label: t('forms.signers.fields.telefono'),
                    name: 'telefono',
                    type: (selectorType !== t('forms.selectors.select')) && ((selectorType === 1)) ? 'text' : ((selectorType === 2 && selectorClient !== t('forms.selectors.select')) ? 'select' : ((selectorType === 3 && selectorRelationShip !== t('forms.selectors.select')) ? 'select' : 'hidden')),
                    settings: {
                      autocomplete: (valuesClient || []).length > 10,
                      emptyElement: t('forms.selectors.select'),
                      options: (filterValidClients(valuesClient) || []),
                    },
                  },
                  {
                    display: {
                      breakpoints: {
                        md: 6,
                        sm: 12,
                        xs: 12,
                      },
                    },
                    label: t('forms.signers.fields.description'),
                    name: 'description',
                    type: (selectorType !== t('forms.selectors.select')) && ((selectorType === 1)) ? 'text' : ((selectorType === 2 && selectorClient !== t('forms.selectors.select')) ? 'text' : ((selectorType === 3 && selectorRelationShip !== t('forms.selectors.select')) ? 'text' : 'hidden')),
                  },
                  {
                    display: {
                      breakpoints: {
                        md: 12,
                        sm: 12,
                        xs: 12,
                      },
                    },
                    type: 'space',
                  },
                ]}
                id="frmAddSigner"
                initialValues={{
                  name: '',
                  email: '',
                  telefono: '',
                  description: '',
                }}
                handleSubmitData={handleSubmitData}
                validationSchema={selectorType === 1 ? addSignerFormSchema : selectorType === 2 ? addSignerFormSchemaClient : addSignerFormSchemaRelationship}
              />
                }
              </>)}
            </>)}
          </Modal>

          <Modal
            children={<>{t('forms.messages.signer_deleting_question')} <b>{modal2?.data?.name}</b>?</>}
            open={modal2?.open}
            title={t('actions.delete_signer')}
            actions={[
              {
                color: 'error',
                label: t('actions.yes'),
                fn: async function () {
                  handleRemoveSigner(modal2?.data?.id);
                },
              },
            ]}
            events={{
              onClose: () => setModal2(),
            }}
          />
        </Box>

        {(openSidebar) && (
          <Box className={classes.boxContainer}>
            <IconButton onClick={handleSidebarToggle}>
              <CloseIcon />
            </IconButton>
            <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)} centered>
              <Tab label={t('actions.label')} />
              <Tab label={t('actions.help')} />
            </Tabs>
            <Box sx={{
              marginTop: 16,
            }}>
              {tabIndex === 0 && (
                <>
                  {data && labels && Object.keys(labels).length > 0 && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      py={3}
                      px={2}
                      bgcolor="#e0f7fa"
                      borderRadius={2}
                      boxShadow={1}
                      mb={2}
                    >
                      <Typography variant="body1" align="center" color="textPrimary">
                        {t('messages.labels_found_one')}
                      </Typography>
                      <Typography variant="body2" align="center" color="textSecondary">
                        {t('messages.labels_found_two')}
                      </Typography>
                    </Box>
                  )}
                  {data && labels && Object.keys(labels).length > 0 ? (
                    Object.keys(labels).map((key) => {
                      return (
                        <DynamicForm
                          key={key}
                          container="card"
                          initialValues={{
                            [cleanString(key)]: Number(labels[key]),
                          }}
                          fields={[
                            {
                              label: key,
                              type: 'divider',
                            },
                            {
                              display: {
                                breakpoints: {
                                  md: 12,
                                  sm: 12,
                                  xs: 12,
                                },
                              },
                              events: {
                                onChange: ({ value }) => {
                                  const val = findValById(Number(value));
                                  setLabels((prevLabels) => ({
                                    ...prevLabels,
                                    [key]: value,
                                  }));
                                  setLabelsMatch((prevLabels) => ({
                                    ...prevLabels,
                                    [key]: val,
                                  }));
                                },
                              },
                              label: 'Campos',
                              name: cleanString(key),
                              settings: {
                                emptyElement: 'Selecciona',
                                autocomplete: true,
                                options: data.map((item) => ({
                                  id: item.id,
                                  name: item.name,
                                })),
                              },
                              type: 'select',
                            },
                          ]}
                        />
                      );
                    })
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      py={3}
                      px={2}
                      bgcolor="#f5f5f5"
                      borderRadius={2}
                      boxShadow={1}
                    >
                      <Typography variant="body1" align="center" color="textSecondary">
                        {t('messages.lables_not_found_one')}
                      </Typography>
                      <Typography variant="body2" align="center" color="textSecondary">
                        {t('messages.lables_not_found_one')}
                      </Typography>
                    </Box>
                  )}
                  {pdfFile && (
                    <>
                      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2} px={2}>
                        {isAdding ? (
                          <>
                            <TextField
                              label= {t('forms.selectors.new_field')}
                              variant="outlined"
                              size="small"
                              value={newLabel}
                              onChange={(e) => setNewLabel(e.target.value)}
                              autoFocus
                              onKeyDown={(e) => e.key === 'Enter' && handleAddLabel()}
                              onBlur={() => setIsAdding(false)}
                            />
                            <Tooltip title= {t('actions.add')}>
                              <IconButton color="primary" onClick={handleAddLabel} sx={{
                                ml: 1,
                              }}>
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <Tooltip title= {t('actions.add_field')}>
                            <IconButton color="primary" onClick={() => setIsAdding(true)}>
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </>
                  )}
                  
                </>
              )}
              {tabIndex === 1 && (
                <>
                  <Box>
                    <Accordion >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" style={{
                          marginBottom: 16,
                        }}>
                          {t('messages.question_one')}             
                        </Typography>
                      </AccordionSummary >
                      <AccordionDetails>
                        <Typography>
                          <Stepper orientation="vertical">
                            {stepsQuestion1.map((label, index) => (
                              <Step key={index} active={true} completed={stepsCompleted[index]}>
                                <StepLabel optional={index === 3 ? <Typography variant="caption">{t('messages.optional')}</Typography> : null}>
                                  <Typography variant="body2">{label}</Typography>
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          {t('messages.question_two')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box> 
                          <p className={classes.helper}>
                            {t('messages.answer_quest_two')}
                          </p>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            py={3}
                            px={2}
                            bgcolor="#e3f2fd"
                            borderRadius={2}
                            boxShadow={1}
                          >
                            <p className={classes.helper}>
                              {t('messages.example')}{' '}
                              <strong>{`{{${t('messages.example_field')}}}`}</strong>
                            </p>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          {t('messages.question_three')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stepper orientation="vertical">
                          {stepsQuestion2.map((step, index) => (
                            <Step key={index} active={true}>
                              <StepLabel icon={step.icon}>
                                <Typography variant="body2">{step.label}</Typography>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

FileAndHtmlControl.defaultProps = {
  disabled: false,
  onChange: () => { },
  settings: {},
};

FileAndHtmlControl.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  settings: PropTypes.shape({
    accept: PropTypes.array,
    base64: PropTypes.bool,
    multiple: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  defaultFile: PropTypes.any,
  defaultFileName: PropTypes.string,
  defaultSignatures: PropTypes.object,
};

export default FileAndHtmlControl;
