import { useState } from 'react';
import {
  any,
  bool,
  func,
  shape,
  string,
} from 'prop-types';
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Avatar,
  Chip,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  Send as SendIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';

import { DynamicForm, Modal } from 'components';
import { getSignedUrl, upload } from 'utils/aws/s3';
import { setPickedKeyValue } from 'utils/objects';
import { parseIfValid } from 'utils/json';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import FormIcon from './FormIcon';

function Form(props) {
  const {
    isLoading,
    message,
    setMessage,
    sendMessage,
    thread,
    file,
    setFile,
  } = props;

  const classes = useStyles();
  const [modal, setModal] = useState();
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.assistant',
  });

  const handleShowModal = function (view, data) {
    setModal({
      data,
      open: true,
      title: {
        attach_file: t('upload_file'),
      }[view],
      view,
    });
  };

  const handleRemoveFile = () => {
    setFile(null);
    setMessage('');
  };

  return (
    <>
      <Modal
        actions={[
          {
            color: 'primary',
            disabled: !modal?.data?.hasFile,
            form: 'frm_attach_file',
            icon: <CloudUploadIcon />,
            label: t('load'),
            processing: modal?.isLoading,
            type: 'submit',
          },
        ]}
        events={{
          onClose: setModal,
        }}
        maxWidth="xs"
        open={modal?.open}
        title={modal?.title}
      >
        <>
          {modal?.view === 'attach_file' && (
            <DynamicForm
              fields={[
                {
                  display: {
                    breakpoints: {
                      xs: 12,
                    },
                  },
                  events: {
                    onChange: function () {
                      const objModal = structuredClone(modal);
                      setPickedKeyValue(objModal, 'data.hasFile', true);
                      setModal(objModal);
                    },
                  },
                  label: t('select_file'),
                  name: 'file',
                  type: 'file',
                  accept: 'image/*',
                },
              ]}
              handleSubmitData={async function (values) {
                try {
                  setModal({
                    ...modal,
                    isLoading: true,
                  });

                  const [file] = values.file;
                  const ext = (file.name.split('.').pop() || '').toLowerCase();
                  const filename = `${thread?.id}_${new Date().getTime()}${ext ? `.${ext}` : ''}`;
                  const s3File = await upload([
                    'company',
                    parseIfValid(localStorage.getItem('company'), false)?.id,
                    'assistant',
                    filename,
                  ].join('/'), file);

                  if (!s3File?.Location) {
                    throw new Error();
                  }

                  const signedUrl = await getSignedUrl(s3File.Location, 60 * 60 * 1000);

                  setFile({
                    name: file.name,
                    url: signedUrl,
                    preview: URL.createObjectURL(file),
                  });

                  setModal();
                } catch (error) {
                  setModal({
                    ...modal,
                    isLoading: false,
                  });
                  console.error(error);
                }
              }}
              id="frm_attach_file"
            />
          )}
        </>
      </Modal>

      <Box mt={2}>
        {file && (
          <Box mb={2} position="relative">
            {file.preview && (
              <Avatar
                src={file.preview}
                variant="rounded"
                style={{
                  width: '40px',
                  height: 'auto',
                  maxHeight: '200px',
                  marginLeft: '2px',
                }}
              />
            )}
            <Chip
              label={file.name}
              onDelete={handleRemoveFile}
              deleteIcon={<CancelIcon />}
              style={{
                marginTop: '8px',
              }}
            >

            </Chip>
          </Box>
        )}

        <TextField
          disabled={isLoading || thread?.error}
          fullWidth
          InputProps={{
            className: classes.input,
            endAdornment: (
              <IconButton
                children={isLoading
                  ? <CircularProgress size={24} />
                  : <SendIcon />
                }
                color="primary"
                disabled={isLoading || (message.length === 0 && !file)}
                onClick={() => {
                  const trimmedMessage = message.trim();
                  if (!trimmedMessage) {
                    console.error('message empty...');
                    return;
                  }
                  sendMessage();
                }}
              />
            ),
            startAdornment: (
              <FormIcon openFileModal={() => handleShowModal('attach_file')} />
            ),
          }}
          multiline
          onChange={({ target }) => setMessage(target?.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              const trimmedMessage = message.trim();
              event.preventDefault();
              event.stopPropagation();
              if (!trimmedMessage) {
                console.error('message empty...');
                return;
              }
              sendMessage();
            }
          }}
          placeholder={t('forms.send_message.fields.text')}
          size="small"
          type="text"
          value={message}
          variant="outlined"
        />
      </Box>
    </>
  );
}

Form.propTypes = {
  isLoading: bool.isRequired,
  message: string,
  setMessage: func.isRequired,
  sendMessage: func.isRequired,
  thread: shape({
    error: any,
  }),
  file: shape({
    name: string,
    url: string,
    preview: string,
  }),
  setFile: func.isRequired,
};

export default Form;
