import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  text: {},
}));

function Breadcrumb({ pieces }) {
  const classes = useStyles();
  const location = useLocation();
  const { i18n, t } = useTranslation('translation', {
    keyPrefix: 'components.breadcrumb',
  });

  const pathParts = pieces
    ? ['', ...pieces]
    : location.pathname.split('/');

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {pathParts.slice(1).map((item, index) => {
        let [label] = Array.isArray(item)
          ? item
          : [item];
        label = i18n.exists(`components.breadcrumb.${label}`) ?
          t(label)
          : label;

        let to = pathParts
          .slice(0, index + 2)
          .map((part) => Array.isArray(part) ? part[1] : part);

        to = to
          .map((_) => to.length > 2 ? _.split('?')[0] : _)
          .join('/');

        if(pathParts[1] === 'debt_collections' || (pathParts[1] === 'pld' && index < 2)) {
          return (
            <Typography
              children={label}
              className={clsx(classes.text)}
              color="textPrimary"
              key={index}
              variant="body1"
            />
          );
        }

        return ![pathParts.length - 2].includes(index)
          ? (<Link
            children={label}
            className={clsx(classes.text)}
            color="inherit"
            component={RouterLink}
            key={index}
            to={to}
            variant="body1"
          />)
          : (<Typography
            children={label}
            className={clsx(classes.text)}
            color="textPrimary"
            key={index}
            variant="body1"
          />);
      })}
    </Breadcrumbs>
  );
}

Breadcrumb.propTypes = {
  pieces: PropTypes.array,
};

export default Breadcrumb;
