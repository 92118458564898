import PropTypes from 'prop-types';

export const DEFAULT_PROPS = {
  footerSeparator: false,
  headers: [],
  rows: [],
  isLoading: false,
  offsetTop: 76,
  size: 'small',
  stickyHeader: true,
  total: 0,
  maxHeightFlag: false,
};

export const PROP_TYPES = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOfType([
      PropTypes.oneOf(['error', 'primary', 'secondary']),
      PropTypes.func,
    ]),
    disabled: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    fn: PropTypes.func,
    hidden: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
    label: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
  })),
  className: PropTypes.any,
  filters: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    values: PropTypes.array,
  }),
  footers: PropTypes.array,
  footerSeparator: PropTypes.bool,
  getDataAction: PropTypes.func,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array,
  mt: PropTypes.number,
  offsetTop: PropTypes.number,
  pagination: PropTypes.shape({
    disabled: PropTypes.bool,
    perPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  rows: PropTypes.array,
  isLoading: PropTypes.bool,
  searchAction: PropTypes.func,
  selectable: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  stickyHeader: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  maxHeightFlag: PropTypes.bool,
  maxHeightValue: PropTypes.number,
};
