import {
  number,
  object,
  string,
  mixed,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

export const templatesFilesFormSchema = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    channel: number()
      .typeError('Selecciona una opción')
      .required(MESSAGES.REQUIRED),
    data: object()
      .nullable()
      .default({}),
    html: string()
      .default(''),
    name: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    subject: string()
      .trim()
      .required(MESSAGES.REQUIRED),
  });

export const templatesFilesFormSchemaFile = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    data: object()
      .nullable()
      .default({}),
    html: string()
      .default(''),
    name: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    file: mixed()
      .required('El archivo es obligatorio') // Validación de campo obligatorio
      .test('isFile', 'Seleccione un archivo .pdf', (value) => {
        // Asegúrate de que el valor es un archivo
        return value instanceof File;
      }),
  });
