import { shuffle } from 'utils/arrays';

export const ASSISTANT_NAME = 'Dynamic Assistant';

export const STATUS = {
  COMPLETED: 'completed',
  OK: 'OK',
  SENDING: 'sending',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
};



export const OK_STATUS = [
  STATUS.COMPLETED,
  STATUS.OK,
];


export const ENDS_STATUS = [
  STATUS.COMPLETED,
  STATUS.FAILED,
  STATUS.CANCELLED,
  STATUS.EXPIRED,
];

export const INSTRUCTIONS = shuffle([
  {
    name: 'functions',
  },
  {
    name: 'dynamicore',
  },
  {
    name: 'condusef',
  },
  {
    name: 'products',
  },
  {
    name: 'marketplace',
  },
]);
