import { Box } from '@material-ui/core';
import { Loader } from 'components';
import React from 'react';
import PropTypes from 'prop-types';

export const LoaderOverlay = ({ message }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <Loader variant='card' message={message} />
    </Box>
  );
};

LoaderOverlay.propTypes = {
  message: PropTypes.string, 
};

LoaderOverlay.defaultProps = {
  message: 'Cargando...',
};

export default LoaderOverlay;
