import {
  number,
  object,
  string,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

export const addSignerFormSchema = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    name: string()
      .default('')
      .required(MESSAGES.REQUIRED),
    email: string()
      .trim()
      .required(MESSAGES.REQUIRED)
      .email(MESSAGES.EMAIL),
    telefono: string()
      .trim()
      .required(MESSAGES.REQUIRED)
      .matches(/^\d{10}$/, MESSAGES.DIGITS_ONLY),
    description: string()
      .default(''),
    type: string()
      .default(''),
  });

export const addSignerFormSchemaClient = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    name: string()
      .default('')
      .required(MESSAGES.REQUIRED),
    email: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    telefono: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    description: string()
      .default(''),
    type: string()
      .default(''),
  });

export const addSignerFormSchemaRelationship = object()
  .shape({
    id: number()
      .positive()
      .integer(),
    name: string()
      .default('')
      .required(MESSAGES.REQUIRED),
    email: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    telefono: string()
      .trim()
      .required(MESSAGES.REQUIRED),
    description: string()
      .default(''),
    type: string()
      .default(''),
  });
