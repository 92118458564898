import PropTypes from 'prop-types';

import useStyles from './styles';
import { Fab } from '@material-ui/core';
import { 
  NoteAdd as NoteAddIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { 
  useHistory, 
  useLocation, 
  useParams, 
} from 'react-router-dom/cjs/react-router-dom.min';
import Dynamicore from 'services/dynamicore';
import { useSnackbar } from 'notistack';
import { Modal } from 'components';
import NoteForm from 'views/Persons/Notes/NoteForm';
import { hasPermissions } from 'utils/permissions';
import { TABS } from './constants';

function NoteLayout({ children }) {
  const classes = useStyles();
  const [modal, setModal] = useState();
  const [tab, setTab] = useState();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleShowModal = function (view) {
    const options = {
      frmNotes: {
        maxWidth: 'md',
        title: 'Agregar Nota',
      },
    }[view] || {};

    options.open = true;

    setModal({
      data: {},
      options,
      view,
    });
  };

  const handleSaveNote = async function (values) {
    let message, variant = 'success';

    try {
      await Dynamicore(tab.url).post({
        type: tab.value,
        type_id: id,
        notes: values?.notes,
      });
      message = 'Nota guardada correctamente';

      if (location.pathname.includes('notes')) {
        history.replace(location.pathname);
      }

      setModal(null);
    } catch (error) {
      message = process.env.NODE_ENV === 'development' && error.message
        ? error.message
        : 'Error al guardar la nota';
      variant = 'error';
    } finally {
      enqueueSnackbar(message, {
        variant,
      });
    }
  };

  useEffect(() => {
    const pathLocation = location.pathname.split('/').filter((segment) => segment !== '')?.[0];

    setTab(TABS.find((tab) => tab.tab === pathLocation));
  }, []);
  

  return (
    <div className={classes.root}>
      <Modal
        {...modal?.options}
        actions={[
          {
            color: 'primary',
            form: 'frmNotes',
            icon: <SaveIcon />,
            label: 'Guardar',
            type: 'submit',
          },
        ]}
        events={{
          onClose: setModal,
        }}
      >
        <NoteForm
          data={modal?.data}
          handleSubmitData={handleSaveNote}
        />
      </Modal>

      {children}

      {hasPermissions('notes.insert.addNotes') && (
        <Fab 
          color="primary" 
          className={classes.fab} 
          onClick={() => handleShowModal('frmNotes')}
          size='medium'
        >
          <NoteAddIcon />
        </Fab>
      )}
    </div>
  );
};

NoteLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default NoteLayout;
