import PropTypes from 'prop-types';

import { DynamicForm } from 'components';
import { noteFormSchema } from 'validations/schema/forms';

function NoteForm({ handleSubmitData, data }) {
  console.debug(data);
  return (
    <DynamicForm
      fields={[
        {
          label: 'Nota',
          name: 'notes',
          type: 'textarea',
          settings: {
            multiline: true,
            rows: 3,
            rowsMax: 5,
          },
        },
      ]}
      id="frmNotes"
      handleSubmitData={handleSubmitData}
      validationSchema={noteFormSchema}
      initialValues={data}
    />
  );
}

NoteForm.defaultProps = {
  handleSubmitData: () => {},
  data: {},
};

NoteForm.propTypes = {
  handleSubmitData: PropTypes.func,
  data: PropTypes.shape({
    notes: PropTypes.string,
  }),
};

export default NoteForm;
