import PropTypes from 'prop-types';
import { Box, styled } from '@material-ui/core';

const Bar = styled(Box)(({ theme, color }) => ({
  width: 5,
  height: 20,
  borderRadius: 4,
  backgroundColor: color || theme.palette.grey[400],
}));

const MatchIndicator = ({ value, max, text }) => {
  const extractNumbers = (text) => {
    const numbers = text?.match(/\d+/g)?.map(Number);
    console.debug('Valores extraídos:', numbers);
    return numbers?.length === 2 ? numbers : [value || 0, max || 1];
  };

  const [finalValue, finalMax] = text ? extractNumbers(text) : [value, max];

  const percentage =
    finalMax > 0 ? Math.min(100, (finalValue / finalMax) * 100) : 0;
  const filledBars = Math.round((percentage / 100) * 5);

  const getColor = (index) => {
    if (index < filledBars) {
      return filledBars <= 1
        ? '#f44336'
        : filledBars <= 3
          ? '#ff9800'
          : '#4caf50';
    }
    return '#bdbdbd';
  };

  return (
    <Box display="flex" sx={{
      justifyContent: 'center',
      gap: 2,
    }}>
      {[...Array(5)].map((_, i) => (
        <Bar key={i} color={getColor(i)} />
      ))}
    </Box>
  );
};

MatchIndicator.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  text: PropTypes.string,
};

MatchIndicator.defaultProps = {
  value: 0,
  max: 1,
  text: null,
};

export default MatchIndicator;
