import moment from 'moment';
import numeral from 'numeral';
import { createElement } from 'react';

import {
  FORMAT_DATE,
  FORMAT_DATETIME,
  FORMAT_NUMBER,
} from 'settings';

function dateTimeFormat(value, format = FORMAT_DATETIME, toLocal = false) {
  let formated = '';
  if (value && moment(value).isValid()) {
    const from = toLocal
      ? moment.utc(value).local()
      : moment.utc(value);
    formated = from.format(format);
  }
  return formated;
}

function dateFormat(value, format = FORMAT_DATE, toLocal = false) {
  return dateTimeFormat(value, format, toLocal);
}

function numberFormat(value = 0, prefix = null, format = FORMAT_NUMBER) {
  return numeral(value)
    .format(`${prefix ? prefix : ''}${format}`);
}

function linkFormat(value) {
  return createElement('a', {
    href: value,
    target: '_blank',
    rel: 'noopener noreferrer',
    children: 'Ver enlace',
  });
}

export {
  dateFormat,
  dateTimeFormat,
  numberFormat,
  linkFormat,
};
