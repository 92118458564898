import { makeStyles } from '@material-ui/core';

import AvatarAssistantImg from 'assets/images/avatar-assistant.png';


export default makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: '8px',
  },
  button_instruction: {
    height: theme.spacing(10),
  },
  code_inline: {
    backgroundColor: '#eeeeee',
    borderColor: '#bcbec0',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0.4em',
    fontFamily: 'monospace',
    paddingLeft: '0.4em',
    paddingRight: '0.4em',
  },
  container: {
    height: 'calc(100vh - 300px)',
    overflow: 'auto',
    borderRadius: 20,
  },
  list: {
    margin: '0 auto',
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logo: {
    margin: '0 auto 20px',
    backgroundColor: '#ffffff',
    backgroundImage: `url(${AvatarAssistantImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '20px',
    boxShadow: '-2px 18px 28px -11px rgba(0, 0, 0, 0.75)',
    filter: 'FlipH',
    height: '90px',
    transform: 'scaleX(-1)',
    width: '90px',
  },
  message: {
    borderColor: theme.palette.secondary.main,
    borderRadius: '20px',
    borderStyle: 'solid',
    borderWidth: '0px',
    margin: '10px',

  },
  message_assistant: {
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}11`,
  },
  message_user: {
    borderColor: theme.palette.success.main,
    backgroundColor: `${theme.palette.success.main}11`,
  },
  paper: {
    boxShadow: 'none',
    marginTop: '15px',
    display: 'inline-block',
    marginLeft: 'auto',
    margin: '8px',
  },
  text: {
    padding: '10px',
  },
}));
