import {
  number,
  object,
  string,
  mixed,
  date,
} from 'yup';

import MESSAGES from 'validations/constants/messages';

export const templatesProcedureClarificationsGralSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    fecha_resolucion: date()
      .typeError('Debe ser una fecha válida'),
    fecha_notifi_usuario: date()
      .typeError('Debe ser una fecha válida'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    monetario: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    monto_reclamado: number()
      .required(MESSAGES.REQUIRED),
    consultas_pori: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    tipo_persona: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    sexo: string()
      .required('Debes seleccionar una opción válida'),
    edad: string()
      .required(MESSAGES.REQUIRED),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    folio_CONDUSEF: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    reversa: string()
      .required('Debes seleccionar una opción válida'),
    operacion_extranjero: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureQueryGralSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    consultas_pori: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureQueryInsuranceSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    ramo: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Debes seleccionar una opción válida'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    folio_CONDUSEF: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    consultas_pori: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureQuerySicsSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    tipo_reporte: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Debes seleccionar una opción válida'),
    clave_SIPRES: string()
      .required(MESSAGES.REQUIRED),
    tipo_persona: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureClaimsCreditInstitutionsSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    fecha_resolucion: date()
      .typeError('Debe ser una fecha válida'),
    fecha_notifi_usuario: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    monetario: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    monto_reclamado: number()
      .required(MESSAGES.REQUIRED),
    importe_abonado: number()
      .required(MESSAGES.REQUIRED),
    fecha_abono_importe: date()
      .typeError('Debe ser una fecha válida'),
    consultas_pori: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    tipo_persona: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    sexo: string()
      .required('Debes seleccionar una opción válida'),
    edad: string()
      .required(MESSAGES.REQUIRED),
    sentido_resolucion: string()
      .required('Debes seleccionar una opción válida'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    folio_CONDUSEF: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    reversa: string()
      .required('Debes seleccionar una opción válida'),
    folio_r27_options: string()
      .required(MESSAGES.REQUIRED)
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    folio_r27: string()
      .max(500, 'El máximo de caracteres permitidos es 500'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureClaimsGralSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    fecha_resolucion: date()
      .typeError('Debe ser una fecha válida'),
    fecha_notifi_usuario: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    monetario: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    monto_reclamado: number()
      .required(MESSAGES.REQUIRED),
    importe_abonado: number()
      .required(MESSAGES.REQUIRED),
    fecha_abono_importe: date()
      .typeError('Debe ser una fecha válida'),
    consultas_pori: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    tipo_persona: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    sexo: string()
      .required('Debes seleccionar una opción válida'),
    edad: string()
      .required(MESSAGES.REQUIRED),
    sentido_resolucion: string()
      .required('Debes seleccionar una opción válida'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    folio_CONDUSEF: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    reversa: string()
      .required('Debes seleccionar una opción válida'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureClaimsInsuranceSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    ramo: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Debes seleccionar una opción válida'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    fecha_resolucion: date()
      .typeError('Debe ser una fecha válida'),
    fecha_notifi_usuario: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    monetario: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    monto_reclamado: number()
      .required(MESSAGES.REQUIRED),
    importe_abonado: number()
      .required(MESSAGES.REQUIRED),
    fecha_abono_importe: date()
      .typeError('Debe ser una fecha válida'),
    consultas_pori: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    tipo_persona: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    sexo: string()
      .required('Debes seleccionar una opción válida'),
    edad: string()
      .required(MESSAGES.REQUIRED),
    sentido_resolucion: string()
      .required('Debes seleccionar una opción válida'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    folio_CONDUSEF: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    reversa: string()
      .required('Debes seleccionar una opción válida'),
    notes: mixed(),
    files: mixed(),
  });

export const templatesProcedureClaimsSicsSchema = object()
  .shape({
    institucion_clave: string()
      .required(MESSAGES.REQUIRED)
      .max(400, 'El máximo de caracteres permitidos es 400'),
    sector: string()
      .required(MESSAGES.REQUIRED)
      .max(200, 'El máximo de caracteres permitidos es 200'),
    consultas_trim: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_folio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    consultas_subfolio: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50')
      .matches(/^[a-zA-Z0-9]+$/, 'No se permiten espacios ni caracteres especiales'),
    consultas_estatus_con: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_fec_acla: date()
      .typeError('Debe ser una fecha válida')
      .required('La fecha es obligatoria'),
    consultas_fec_aten: date()
      .typeError('Debe ser una fecha válida'),
    medios_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    product: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    causas_id: string()
      .required('Debes seleccionar una opción válida')
      .notOneOf(['Selecciona'], 'Debes seleccionar una opción válida diferente de "Selecciona"'),
    fecha_resolucion: date()
      .typeError('Debe ser una fecha válida'),
    fecha_notifi_usuario: date()
      .typeError('Debe ser una fecha válida'),
    estados_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_cp: number()
      .required(MESSAGES.REQUIRED)
      .typeError('Ingresa un código postal válido'),
    consultas_mpio_id: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultas_loc_id: number()
      .typeError('Debes seleccionar una opción válida'),
    consultas_col_id: number()
      .typeError('Debes seleccionar una opción válida'),
    tipo_persona: number()
      .required('Debes seleccionar una opción válida')
      .typeError('Debes seleccionar una opción válida'),
    consultascatnivelaten_id: string()
      .required('Debes seleccionar una opción válida'),
    clave_SIPRES: string()
      .required(MESSAGES.REQUIRED),
    folio_CONDUSEF: string()
      .required(MESSAGES.REQUIRED)
      .max(50, 'El máximo de caracteres permitidos es 50'),
    reversa: string()
      .required('Debes seleccionar una opción válida'),
    notes: mixed(),
    files: mixed(),
  });
