import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Assistant as AssistantIcon } from '@material-ui/icons';
import { useAssistant } from 'components/Wrappers/AssistantWrapper';

function Assistant() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'system.menu.assistant',
  });
  const { setShowChat } = useAssistant();

  return (<>
    <Tooltip title={t('tooltip')}>
      <IconButton
        children={<AssistantIcon />}
        color="inherit"
        onClick={() => setShowChat(true)}
      />
    </Tooltip>
  </>);
}

export default Assistant;
