import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const riskFormSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.REQUIRED),
  description: Yup.string().required(MESSAGE.REQUIRED),
  value1: Yup.number()
    .typeError('Debe ser un número')
    .min(1, 'El valor debe ser al menos 1')
    .max(100, 'El valor no puede ser mayor a 100')
    .when('value2', (value2, schema) =>
      value2 !== undefined ? schema.lessThan(value2, 'Debe ser menor que Max') : schema,
    )
    .required(MESSAGE.REQUIRED),
  value2: Yup.number()
    .typeError('Debe ser un número')
    .min(0, 'El valor debe ser al menos 0')
    .max(100, 'El valor no puede ser mayor a 100')
    .required(MESSAGE.REQUIRED),
});

