import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const noteFormSchema = Yup
  .object()
  .shape({
    notes: Yup
      .string()
      .required(MESSAGE.REQUIRED),
  });
