import {
  Fragment,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  BlurCircular as BlurCircularIcon,
  Error as ErrorIcon,
  NotificationsActive as NotificationsActiveIcon,
  NotificationsNone as NotificationsNoneIcon,
  NotificationImportant as NotificationImportantIcon,
} from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

import { dateTimeFormat } from 'utils/formater';

const typeIcons = {
  default: BlurCircularIcon,
  error: ErrorIcon,
  notification: NotificationImportantIcon,
};

const typeColors = {
  error: 'icon_error',
  notification: 'icon_notification',
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon_default: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  icon_notification: {
    backgroundColor: green[700],
    color: theme.palette.secondary.contrastText,
  },
  icon_error: {
    backgroundColor: red[700],
    color: theme.palette.secondary.contrastText,
  },
}));

function Notifications() {
  const classes = useStyles();
  const { pendings } = useSelector(({ notifications }) => notifications);
  const { t } = useTranslation('translation', {
    keyPrefix: 'system.menu.notifications',
  });

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('tooltip')}>
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Badge badgeContent={pendings.total} color="default" overlap='rectangular'>
            <SvgIcon
              children={pendings.total
                ? <NotificationsActiveIcon />
                : <NotificationsNoneIcon />
              }
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popover,
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            children={t('title')}
            color="textPrimary"
            variant="h5"
          />
        </Box>
        {pendings.total === 0 ? (
          <Box p={2}>
            <Typography
              children={t('empty')}
              color="textPrimary"
              variant="h6"
            />
          </Box>
        ) : (
          <List className={classes.list} disablePadding>
            {pendings.rows.slice(0, 5).map((item, index) => {
              const Icon = typeIcons[item?.message?.alert_type || 'default'] || Fragment;

              return (
                <ListItem
                  className={classes.listItem}
                  component={RouterLink}
                  divider
                  key={index}
                  onClick={handleClose}
                  to={`/notifications/${item.id}`}
                >
                  <ListItemAvatar>
                    <Avatar className={classes[typeColors[item?.message?.alert_type] || 'icon_default']}>
                      <SvgIcon fontSize="small">
                        <Icon />
                      </SvgIcon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item?.message?.name}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      color: 'textPrimary',
                    }}
                    secondary={dateTimeFormat(item.created_at, undefined, true)}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
        <Box p={1} display="flex" justifyContent="center">
          <Button
            children={t('show_more')}
            component={RouterLink}
            onClick={handleClose}
            size="small"
            to="/notifications"
          />
        </Box>
      </Popover>
    </>
  );
}

export default Notifications;
