import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark as syntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  any,
  array,
  func,
  shape,
  string,
} from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { WbIncandescentOutlined as WbIncandescentOutlinedIcon } from '@material-ui/icons';

import AvatarAssistantImg from 'assets/images/avatar-assistant.png';
import { AWS_BUCKET } from 'settings/aws';
import { download } from 'utils/aws/s3';
import { dateTimeFormat } from 'utils/formater';

import {
  ASSISTANT_NAME,
  INSTRUCTIONS,
  STATUS,
} from '../constants';
import LoaderComponent from '../Loader';
import useStyles from './styles';

function MessagesCard(props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'views.assistant',
  });
  const {
    messagesEndRef,
    sendMessage,
    thread,
  } = props;

  return (
    <Box mt={2} >
      <Card className={classes.container}>
        <CardContent>

          {thread?.error && (<>
            <div className={classes.logo} />

            <Alert severity="error">
              <AlertTitle children={ASSISTANT_NAME} />
              {t("service_not_available")}
            </Alert>
          </>)}

          {(!thread?.error && (thread?.messages ?? []).length === 0) && (
            <>
              <Box textAlign="center">
                <div className={classes.logo} />

                <Typography
                  children={`${t('messages.welcome')} ${ASSISTANT_NAME}`}
                  gutterBottom
                  variant="h4"
                />
                <Typography
                  children={`${ASSISTANT_NAME} ${t('messages.assistant_description')}`}
                />
                <Typography
                  children={t('messages.make_your_life_easier')}
                />
              </Box>

              <Box mt={3}>
                <Grid container className={classes.list} spacing={2}>
                  {INSTRUCTIONS.map((item, index) => (
                    <div
                      style={{
                        margin: 5,
                        width: '100%',
                      }}
                      key={index}
                    >
                      <Button
                        children={t(`messages.${item.name}`)}
                        className={classes.button_instruction}
                        color="primary"
                        fullWidth
                        onClick={() =>
                          sendMessage(t(`messages.${item.name}`))
                        }
                        startIcon={<WbIncandescentOutlinedIcon />}
                        variant="outlined"
                      />
                      {false && <Card className={classes.card_instructions}>
                        <CardContent>
                          <Typography
                            children={item.description}
                            variant="caption"
                          />
                        </CardContent>
                      </Card>
                      }
                    </div>
                  ))}
                </Grid>
              </Box>
            </>)}

          {(thread?.messages ?? []).map((message, index) => {
            const classMessage = clsx(
              classes.message,
              classes[`message_${message.role}`],
            );
            return (
              <div key={`${message.id}-${index}`}
                style={{
                  display: message.role === 'user' ? 'flex' : 'auto',
                }}>
                <Paper className={classes.paper} >
                  <Grid container wrap="nowrap" spacing={2}>
                    {
                      message.role === 'assistant' &&
                      <Grid item>
                        <Avatar
                          src={AvatarAssistantImg}
                          style={{
                            filter: 'FlipH',
                            transform: 'scaleX(-1)',
                          }}
                        />
                      </Grid>
                    }
                    <Grid className={classMessage} item xs>

                      <Typography
                        children={dateTimeFormat(new Date(message.created_at * 1000))}
                        variant="caption"
                        className={classes.text}
                      />

                      {(message?.content ?? []).length === 0 && message.role === 'assistant' && (
                        <div className={classes.text}>
                          {thread.status === 'in_progress' || thread.status === STATUS.SENDING ? (
                            <LoaderComponent />
                          ) : (
                            <LoaderComponent />
                          )}
                        </div>
                      )}


                      {(message?.content ?? []).map((item, index) => (
                        <div key={index}
                          className={classes.text}>
                          <Markdown
                            children={item?.text?.value ?? ''}
                            components={{
                              a: ({ children, node }) => {
                                const bucketUrl = `https://${AWS_BUCKET}.s3.us-west-2.amazonaws.com`;
                                const aProps = {};

                                if (String(node?.properties?.href).startsWith(bucketUrl)) {
                                  aProps.onClick = function () {
                                    download(node?.properties?.href);
                                  };
                                } else {
                                  aProps.href = node?.properties?.href;
                                  aProps.rel = 'noreferrer';
                                  aProps.target = '_blank';
                                }

                                return (
                                  <a
                                    children={children}
                                    className={classes.link}
                                    {...aProps}
                                  />
                                );
                              },
                              code: ({ children, className, inline, node, ...props }) => {
                                const match = /language-(\w+)/.exec(className || '');

                                return !inline && match
                                  ? (<>
                                    <SyntaxHighlighter
                                      {...props}
                                      children={String(children).replace(/\n$/, '')}
                                      style={syntaxStyle}
                                      language={match[1]}
                                      PreTag="div"
                                    />
                                  </>)
                                  : (<code
                                    {...props}
                                    className={classes.code_inline}
                                    children={children}
                                  />);
                              },
                              li: ({ children }) => <li
                                children={children}
                                style={{
                                  margin: '12px auto',
                                }}
                              />,
                              ol: ({ children }) => <ol
                                children={children}
                                style={{
                                  padding: 'revert',
                                }}
                              />,
                              ul: ({ children }) => <ul
                                children={children}
                                style={{
                                  padding: 'revert',
                                }}
                              />,
                            }}
                          />
                        </div>
                      ))}

                    </Grid>
                    {message.role === 'user' && (
                      <Grid item>
                        <Avatar
                          className={classes.avatar}
                          children={[
                            String(thread?.user?.name)[0],
                            String(thread?.user?.family_name)[0],
                          ].filter((str) => str).join('') || 'U'}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </div>
            );
          })}
        </CardContent>
        <div ref={messagesEndRef} />
      </Card>
    </Box>
  );
}

MessagesCard.propTypes = {
  messagesEndRef: any,
  sendMessage: func,
  thread: shape({
    error: any,
    messages: array,
    user: shape({
      family_name: string,
      name: string,
    }),
  }),
};

export default MessagesCard;
