import { Fragment } from 'react';
import { Form, Formik } from 'formik';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
  withWidth,
} from '@material-ui/core';

import { Divider, Loader } from 'components';
import { pickValue } from 'utils/objects';

import {
  CaptchaControl,
  CardSelector,
  CodeControl,
  ColorControl,
  CronControl,
  DateControl,
  DocumentControl,
  FileControl,
  GoogleMapsControl,
  HtmlControl,
  JsonControl,
  KeyValueControl,
  ListControl,
  PhoneNumberControl,
  NumberControl,
  SelectControl,
  SliderControl,
  SwitchControl,
  SplittedTextControl,
  TextControl,
  ButtonControl,
  FileAndHtmlEditor,
} from './controls';
import FormErrorNotification from './FormErrorNotification';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const CONTROL = {
  CAPTCHA: 'captcha',
  CARDSELECTOR: 'cardselector',
  CHECKBOX: 'checkbox',
  CODE: 'code',
  COLOR: 'color',
  CRON: 'cron',
  DATE: 'date',
  DOCUMENT: 'document',
  GOOGLE_MAPS: 'googlemaps',
};

const CONTROLS = {
  [CONTROL.CAPTCHA]: CaptchaControl,
  [CONTROL.CARDSELECTOR]: CardSelector,
  [CONTROL.CODE]: CodeControl,
  [CONTROL.COLOR]: ColorControl,
  [CONTROL.CRON]: CronControl,
  [CONTROL.CHECKBOX]: SwitchControl,
  [CONTROL.DATE]: DateControl,
  divider: '', // FIXME: Delete type
  [CONTROL.DOCUMENT]: DocumentControl,
  email: TextControl,
  file: FileControl,
  [CONTROL.GOOGLE_MAPS]: GoogleMapsControl,
  hidden: TextControl,
  html: HtmlControl,
  json: JsonControl,
  keyvalue: KeyValueControl,
  list: ListControl,
  phone_number: PhoneNumberControl,
  number: NumberControl,
  password: TextControl,
  select: SelectControl,
  slider: SliderControl,
  switch: SwitchControl,
  splitted: SplittedTextControl,
  text: TextControl,
  textarea: TextControl,
  file_and_html: FileAndHtmlEditor,
};

function getControl(opts) {
  const { settings } = opts;
  let { type } = opts;

  if (settings?.format === 'phone_number') {
    type = 'phone_number';
  }

  return CONTROLS[type] || TextControl;
}

function CardContainer({ children, className, title }) {

  return (<Box mt={2}>
    <Card className={className}>
      {title && <CardHeader title={title} />}
      <CardContent children={children} />
    </Card>
  </Box>);
}

CardContainer.propTypes = {
  children: node,
  className: node,
  title: string,
};

function DynamicForm(props) {
  const classes = useStyles();
  const {
    actions,
    isLoading,
    debug,
    fields,
    handleSubmitData,
    id,
    initialValues,
    onValidationError,
    spacing,
    validationSchema,
    width,
  } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.datagrid',
  });

  async function onSubmit(values, formikHelpers) {
    const {
      // resetForm,
      setErrors,
      setStatus,
      setSubmitting,
    } = formikHelpers;

    try {
      await setSubmitting(true);
      await handleSubmitData(validationSchema.cast(values));

      setStatus({
        success: true,
      });
    } catch (error) {
      setStatus({
        success: false,
      });
      setErrors({
        submit: error.message,
      });
    } finally {
      setSubmitting(false);
    }
  }

  if (!Array.isArray(fields)) {
    const Control = getControl(fields);

    return <Control
      {...fields}
      size={props.size}
      variant={props.variant}
    />;
  }

  if (
    (typeof isLoading === 'boolean' && isLoading) ||
    (typeof isLoading === 'object' && isLoading !== null && isLoading.isLoadingForm)
  ) {
    return (
      <>
        {typeof isLoading === 'object' ? (
          <Box style={{
            padding: 20,
          }}>
            <Loader
              message={t('loading')}
              size={24}
            />
          </Box>
        ) : (
          <Card className={classes[props?.container]} style={{
            padding: 20,
            marginBlock: 20,
          }}>
            <Loader
              message={t('loading')}
              size={24}
            />
          </Card>
        )}
      </>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const {
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          // isSubmitting,
          // isValid,
          setFieldTouched,
          // setFieldValue,
          touched,
          values,
        } = formikProps;

        const onSubmitForm = function (e) {
          handleSubmit(e);

          fields.forEach((f) => {
            if (f.name) {
              setFieldTouched(f.name, true);
            }
          });
        };

        const Container = {
          card: CardContainer,
          card_round: CardContainer,
        }[props?.container] ?? CardContainer;

        if (props.onChange) {
          props.onChange(values, formikProps);  // Enviamos los valores actuales del formulario
        }

        return (
          <Form onReset={handleReset} onSubmit={onSubmitForm} id={id}>
            <FormErrorNotification
              debug={debug}
              onValidationError={onValidationError}
            />

            <Container className={classes[props?.container]}>
              <Grid container spacing={spacing}>
                {fields
                  .map((item, index) => {
                    const Control = getControl(item);
                    const { display: { size = props.size } = {} } = item;

                    let {
                      display: {
                        breakpoints = {
                          md: 12,
                        },
                      } = {},
                    } = item;

                    switch (item.type) {
                      case CONTROL.CAPTCHA:
                      case CONTROL.CARDSELECTOR:
                      case CONTROL.DOCUMENT:
                        breakpoints = {
                          lg: 12,
                          md: 12,
                          sm: 12,
                          xl: 12,
                          xs: 12,
                        };
                        break;

                      default:
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'actions') {
                      return (<Grid key={index} item {...breakpoints}>
                        {item?.settings?.options.map((option, index) => (
                          <IconButton
                            children={option.icon}
                            disabled={option.disabled}
                            key={index}
                            size="small"
                            onClick={option.fn}
                          />
                        ))}
                      </Grid>);
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'description') {
                      if (item.settings?.isDocumentExtension && item.label) {
                        return (<Grid key={index} >
                          <Typography
                            className = {classes.document_extension}
                            children={item.label}
                          />
                        </Grid>);
                      }
                      return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                        <Typography
                          align="justify"
                          children={item.label}
                        />
                      </Grid>);
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'link') {
                      return (<Grid key={index} item {...breakpoints}>
                        <Link
                          children={item.label}
                          color={item.color}
                          component={RouterLink}
                          to={item.to}
                          variant="body2"
                        />
                      </Grid>);
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'btn_link_submit') {
                      return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                        <Button
                          style={item.style}
                          color={item.color}
                          disabled={item.processing}
                          fullWidth
                          size="large"
                          type={'submit'}
                          variant="contained"
                          onClick={() => item.fn()}
                        >
                          {item.processing && (<CircularProgress
                            size={18}
                            style={{
                              marginRight: 5,
                            }}
                          />)}
                          {item.label}
                        </Button>
                      </Grid>);
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'space') {
                      return (<Grid key={index} item {...breakpoints}>
                        <div style={{
                          height: `${width === 'md' || width === 'lg' ? item.height : 0}px`,
                        }}>&nbsp;</div>
                      </Grid>);
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'offset') {
                      return breakpoints[width] !== 12 ? (<Grid
                        item
                        key={index}
                        style={{
                          padding: '0px',
                        }}
                        {...breakpoints}
                      />) : null;
                    }

                    // FIXME: Eliminar, pasar a usar type: 'component'
                    if (item.type === 'divider') {
                      return (<Grid
                        children={<Divider
                          align={item?.display?.align}
                          children={item.label}
                          variant={item?.display?.variant || props.variant}
                        />}
                        key={index}
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xl={12}
                        xs={12}
                      />);
                    }

                    if (item.type === 'hidden') {
                      return (<></>);
                    } else {
                      const gridControls = [];

                      if (item?.display?.offset?.before) {
                        gridControls.push(
                          <Grid
                            item
                            {...item?.display?.offset?.before}
                          />);
                      }
                      if(item.settings?.isDocumentName) {
                        gridControls.push(<Grid key={`${item.name || ''}_grid_${index}`} >
                          <Control
                            {...item}
                            key={`${item.name || ''}_${index}`}
                            error={(pickValue(touched, item.name) && pickValue(errors, item.name)) || item?.message?.level === 'error'}
                            helperText={pickValue(touched, item.name)
                              ? pickValue(errors, item.name)
                              : item?.message?.text}
                            size={size}
                            value={pickValue(values, item.name)}
                            onBlur={handleBlur}
                            onChange={async (event) => {
                              handleChange(event);
                            }}
                          />
                        </Grid>);
                      }else{
                        gridControls.push(<Grid key={`${item.name || ''}_grid_${index}`} item {...breakpoints}>
                          <Control
                            {...item}
                            key={`${item.name || ''}_${index}`}
                            error={(pickValue(touched, item.name) && pickValue(errors, item.name)) || item?.message?.level === 'error'}
                            helperText={pickValue(touched, item.name)
                              ? pickValue(errors, item.name)
                              : item?.message?.text}
                            size={size}
                            value={pickValue(values, item.name)}
                            onBlur={handleBlur}
                            onChange={async (event) => {
                              handleChange(event);
                            }}
                            variant={item?.display?.variant || props.variant}
                          />
                        </Grid>);
                      }
                      if (item?.display?.offset?.after) {
                        gridControls.push(
                          <Grid
                            item
                            {...item?.display?.offset?.after}
                          />);
                      }

                      return gridControls;
                    }
                  })}
              </Grid>
            </Container>

            {actions && actions
              .filter((_) => !_.hidden)
              .map((action, index) => (
                <Box key={index} mt={2}>
                  {/* FIXME: Eliminar, pasar a usar type: 'component' */}
                  {action.typeButton === 'login' ? (
                    <ButtonControl
                      label={action.label}
                      color={action.color}
                      disabled={action?.disabled || action?.processing}
                      fullWidth
                      size="large"
                      type='submit'
                      variant="contained"
                    />
                  ) : (
                    <Button
                      color={action.color}
                      disabled={action.processing}
                      fullWidth
                      size="large"
                      type={action.type}
                      variant="contained"
                    >
                      {action.processing && (<CircularProgress
                        size={18}
                        style={{
                          marginRight: 5,
                        }}
                      />)}
                      {action.label}
                    </Button>
                  )}
                </Box>
              ))}

          </Form>
        );
      }}
    </Formik>
  );
}

const propFields = shape({
  display: shape({
    breakpoints: shape({
      lg: number,
      md: number,
      sm: number,
      xl: number,
      xs: number,
    }),
    size: oneOf([
      'small',
      'medium',
    ]),
    variant: oneOf([
      'outlined',
      'standard',
    ]),
  }),
  message: shape({
    level: string,
    text: string,
  }),
  label: string,
  name: string,
  placeholder: string,
  settings: shape({
    emptyElement: string,
    loadingElements: bool,
    format: oneOf([
      'clabe',
      'credit_card',
      'credit_card_expire_time',
      'currency',
      'mx_phone',
      'percentage',
      'phone_number',
    ]),
    max: number,
    min: number,
    multiline: bool,
    options: oneOfType([
      arrayOf(shape({
        id: oneOfType([
          number,
          string,
        ]),
        name: string,
      })),
      func,
    ]),
    rows: number,
    rowsMax: number,
    step: number,
  }),
  type: oneOf(Object.keys(CONTROLS)),
});

DynamicForm.defaultProps = {
  container: 'default',
  debug: false,
  fields: [],
  handleSubmitData: () => { },
  id: `dynamic_form_${Math.floor(Math.random() * 10000)}`,
  initialValues: {},
  isLoading: false,
  onValidationError: () => { },
  spacing: 2,
  size: 'small',
  validationSchema: Yup.object(),
  variant: 'outlined',
};

DynamicForm.propTypes = {
  actions: arrayOf(shape({
    color: oneOf(['primary', 'secondary']),
    disabled: bool,
    hidden: bool,
    label: string,
    processing: bool,
    type: oneOf(['submit']),
  })),
  container: oneOf(['card', 'default']),
  debug: bool,
  fields: oneOfType([
    arrayOf(propFields),
    propFields,
  ]),
  handleSubmitData: func,
  id: string,
  isLoading: bool,
  initialValues: object,
  onValidationError: func,
  spacing: number,
  size: oneOf([
    'small',
    'medium',
  ]),
  validationSchema: object,
  variant: oneOf([
    'outlined',
    'standard',
  ]),
  width: string,
  onChange: func,
};

export default withWidth()(DynamicForm);
