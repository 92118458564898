import { SERVICES } from 'services/dynamicore';

export const TAB = {
  PEOPLE: 'people',
  PRODUCTS: 'product',
};

export const TABS = [
  {
    value: 'client',
    tab: TAB.PEOPLE,
    url: SERVICES.THIRDS_NOTES,
  },
  {
    value: 'product',
    tab: TAB.PRODUCTS,
    url: SERVICES.THIRDS_NOTES,
  },
];
